import {
  AttributeGrouped,
  IGetAttributesParams,
  AttributeWidgetByEntityItem,
  AttributeSet,
  IGetAttributesTableListParams,
  CreateAttributeSetParams,
  AttributeItem,
  IGetAttributesFilterParams,
  AttributeNameIds,
  AttributeListAll,
  setActiveMutationParam,
  IGetAttributeInstancesCountParams,
  SetWithAttributes,
  IAttributeFilterParams,
  DeleteAttributesSetParams,
  UpdateAttributeSetParams,
  AttributeTableResponse,
  IGetDatasourceAttributesParams,
  AttributeTypeInfo,
  AttributeConditionParams,
  AttributeDataSourceInfo,
  AttributeDetailsParams,
  AttributeUserAccess,
  AttributeGroupAccess,
  AttributeSetAttribute
} from './attributesSlice'
import { CustomAttribute, CustomAttributeData, CustomAttributeListItem } from './settings/types'
import { DetectionType, RegexType } from './settings/constants'
import {
  API_PARAM_ATTRIBUTE,
  ENTITY_ID,
  LIMIT_DEFAULT,
  OWNER_TYPE_SYSTEM,
  OWNER_TYPE_USER,
  ATTRIBUTE_SET_TYPES,
  FILTER_ATTRIBUTE_DATASOURCEIDS_KEY,
  ALERT_ID,
  DATA_SOURCE_ID,
  EMPTY_CONTENT,
  CLASSICATION_TYPE,
  API_PARAM_PROJECT_ID,
  DATA_SOURCE_TYPE,
  MAIL_DATASOURCES,
  TEMPLATE1_DATASOURCES,
  STRUCTURED_DATASOURCES,
  DATA_SOURCE_TYPES,
  FILTER_FOLDER_IDS,
  SENSITIVE_LABEL
} from '../../constants'
import {
  getAfterCursor,
  parameterizeArrayofObjects,
  stringifyParams
} from '../../utils/graphqlUtil'
import { AlertAttributeCondition } from '../policies/policiesSlice'
import { distinctArrayOfObjects } from '../../utils'
import { FilterParams } from '../../interfaces'
import { getUserFiltersString } from '../accessControl/queries'
import {
  AccessControlUserGroupByDepartmentItem,
  DataSensitivityLevels
} from '../accessControl/types'
import { escapeNewlineandTab } from '../../utils/stringUtil'
import { isArrayEmptyOrFalsy } from '../../utils/common'
import { gql } from 'graphql-request'

enum ATTRIBUTE_FILTER_KEYS {
  name = 'NAME',
  project = 'PROJECT_IDS',
  bucket = 'BUCKET_NAMES',
  drive = 'DRIVE_IDS',
  partner = 'PARTNER_IDS',
  dataSourceIds = 'DATASOURCE_IDS',
  classification = 'CLASSIFICATION'
}

export const queryTopAttributeInstances = (
  params: IGetAttributesParams & IAttributeFilterParams
): string => {
  const { userEntityId, filter } = params
  let paramStr = ''
  if (userEntityId) {
    paramStr += `entityId:"${userEntityId}"`
  }
  let commonParamString = ''
  const filterString = filter ? parameterizeArrayofObjects(filter) : ''
  if (filterString) {
    commonParamString += `,filter: ${filterString}`
  }
  return gql`
  {
    attributeInstanceGroupedbyName(${paramStr}${commonParamString}) {
      name
      internalName
      attributeInstanceCount
    }
  }`
}

export const queryAwsTopAttributeInstances = (
  params: IGetAttributesParams & IAttributeFilterParams
): string => {
  const { filter } = params
  let commonParamString = ''
  const filterString = filter ? parameterizeArrayofObjects(filter) : ''
  if (filterString) {
    commonParamString += `,filter: ${filterString}`
  }
  if (commonParamString) {
    commonParamString = '(' + commonParamString + ')'
  }
  return gql`
  {
    attributeInstanceGroupedbyName${commonParamString} {
      name
      internalName
      attributeInstanceCount
    }
  }`
}

// TODO: add type for graphql response
export const mapQueryTopAttributeInstances = (raw: any): AttributeWidgetByEntityItem[] => {
  return raw.attributeInstanceGroupedbyName.map((attribute) => ({
    name: attribute.name,
    internalName: attribute.internalName,
    count: attribute.attributeInstanceCount || 0
  }))
}

export const queryAttributesAccess = (params: IGetAttributesParams): string => {
  const { datasourceId } = params

  const filterDataSource = datasourceId ? `datasourceIds: ["${datasourceId}"],` : ''
  return gql`
    {
      attribute(first: 999, ${filterDataSource}) {
        edges {
          node {
            id
            name
            internalName
            userAccess { count }
          }
        }
      }
    }
  `
}
export const mapQueryAttributesAccess = (
  raw: any
): { id: string; name: string; internalName: string; userAccessCount: number }[] => {
  return raw.attribute.edges.map(({ node }) => ({
    id: node.id,
    name: node.name,
    internalName: node.internalName,
    userAccessCount: node.userAccess?.count || 0
  }))
}

export const queryDatasourceAttributeCards = (params: IGetDatasourceAttributesParams): string => {
  const isMail = MAIL_DATASOURCES.find(
    (datasourceType) => datasourceType === params[DATA_SOURCE_TYPE]
  )
  const isDrive =
    TEMPLATE1_DATASOURCES.find((datasourceType) => datasourceType === params[DATA_SOURCE_TYPE]) ||
    STRUCTURED_DATASOURCES.find((datasourceType) => datasourceType === params[DATA_SOURCE_TYPE])

  const isJira = params[DATA_SOURCE_TYPE] === DATA_SOURCE_TYPES.jira
  const isExchange = params[DATA_SOURCE_TYPE] === DATA_SOURCE_TYPES.exchange
  const isAws = params[DATA_SOURCE_TYPE] === DATA_SOURCE_TYPES.aws
  const isGoogleCloudStorage = params[DATA_SOURCE_TYPE] === DATA_SOURCE_TYPES.googleCloudStorage
  const isAzureBlob = params[DATA_SOURCE_TYPE] === DATA_SOURCE_TYPES.azureBlob
  const isBox = params[DATA_SOURCE_TYPE] === DATA_SOURCE_TYPES.box
  const isDropbox = params[DATA_SOURCE_TYPE] === DATA_SOURCE_TYPES.dropbox
  const isSlack = params[DATA_SOURCE_TYPE] === DATA_SOURCE_TYPES.slack
  const isTeams = params[DATA_SOURCE_TYPE] === DATA_SOURCE_TYPES.teams
  const isGenericDatasource = params[DATA_SOURCE_TYPE] === DATA_SOURCE_TYPES.generic

  const filterEntity = params[ENTITY_ID] ? `entityId: "${params[ENTITY_ID]}"` : ''
  const filterAlert = params[ALERT_ID] ? `, alertId: "${params[ALERT_ID]}"` : ''
  const { subAlertAssignees = [] } = params
  const filterSubAlertAssignees =
    subAlertAssignees.length > 0 ? `, subAlertAssignees: ${JSON.stringify(subAlertAssignees)}` : ''

  const dataSourceFilter = params.datasourceId
    ? `,{key: ${ATTRIBUTE_FILTER_KEYS.dataSourceIds}, values: ${JSON.stringify(
        params.datasourceId?.split(',')
      )}}`
    : ''
  const attributeFilter =
    params.attribute && params.attribute.length > 0
      ? `{key: ${ATTRIBUTE_FILTER_KEYS.name}, values: ${JSON.stringify(
          params[API_PARAM_ATTRIBUTE]?.split(',')
        )}}`
      : ''
  const projectFilter = params.projectId
    ? `,{key: ${ATTRIBUTE_FILTER_KEYS.project}, values: ${JSON.stringify(
        params[API_PARAM_PROJECT_ID]?.split(',')
      )}}`
    : ''

  const bucketFilter = params.bucketId
    ? `,{key: ${ATTRIBUTE_FILTER_KEYS.bucket}, values: ["${params.bucketId}"]}`
    : ''

  const driveFilter = params.driveId
    ? `,{key: ${ATTRIBUTE_FILTER_KEYS.drive}, values: ["${params.driveId}"]}`
    : ''

  const partnerFilter = params.partnerId
    ? `,{key: ${ATTRIBUTE_FILTER_KEYS.partner}, values: ["${params.partnerId}"]}`
    : ''
  const folderFilter = params.folderId
    ? `,{key: ${FILTER_FOLDER_IDS}, values: ["${params.folderId}"]}`
    : ''
  const classFilter = params['class[]']
    ? `,{key: ${ATTRIBUTE_FILTER_KEYS.classification}, values: ["${params['class[]']}"]}`
    : ''

  const filter =
    attributeFilter ||
    projectFilter ||
    bucketFilter ||
    driveFilter ||
    partnerFilter ||
    classFilter ||
    folderFilter ||
    dataSourceFilter
      ? `, filter: [${attributeFilter}${projectFilter}${bucketFilter}${driveFilter}${partnerFilter}${classFilter}${folderFilter}${dataSourceFilter}]`
      : ''
  /**
   * Temporarily removed object count for LA-3808
   *  objects : mail {
        count
      }
   */
  return isMail || isExchange
    ? gql`
  {
    attributeInstanceGroupedbyName(${filterEntity} ${filterAlert} ${filterSubAlertAssignees} ${filter}) {
      name
      internalName
      sensitivity
      datasources(first: 999) {
        edges {
          node {
            id
            name
            type
          }
        }
        count
      }
      attributeInstanceCount
    }
  }
`
    : params?.blobId
    ? gql`
    {
      columns(id: "${params?.blobId}") {
        edges {
          node {
            attributeInstanceGroupedbyName(${filterEntity} ${filterAlert} ${filterSubAlertAssignees} ${filter}) {
              edges {
                node {
                  name
                  internalName
                  sensitivity
                  rowCount
                  attributeInstanceCount
                }
              }
            }
          }
        }
      }
    }
  `
    : isSlack ||
      isDrive ||
      isAws ||
      isGoogleCloudStorage ||
      isBox ||
      isAzureBlob ||
      isTeams ||
      isDropbox
    ? `{
  attributeInstanceGroupedbyName(${filterEntity} ${filterAlert} ${filterSubAlertAssignees} ${filter}) {
    name
    internalName
    sensitivity
    objects{
      count
    }
    attributeInstanceCount
  }
}`
    : isGenericDatasource
    ? gql`
    {
      attributeInstanceGroupedbyName(${filterEntity} ${filter} ${filterSubAlertAssignees}) {
        name
        internalName
        sensitivity
        objects{
          count
        }
        attributeInstanceCount
      }
    }
  `
    : isJira
    ? gql`
    {
      attributeInstanceGroupedbyName(${filterEntity} ${filterAlert} ${filter} ${filterSubAlertAssignees}) {
        name
        internalName
        sensitivity
        tickets {
          count
        }
        attributeInstanceCount
      }
    }
  `
    : gql`
    {
      attributeInstanceGroupedbyName(${filterEntity} ${filterAlert} ${filterSubAlertAssignees} ${filter}) {
        name
        internalName
        sensitivity
        objects {
          count
        }
        datasources(first: 999) {
          edges {
            node {
              id
              name
              type
            }
          }
          count
        }
        attributeInstanceCount
      }
    }
  `
}

export const mapQueryDatasourceAttributeCards = (
  raw: any,
  sensitivity?: string
): AttributeGrouped[] => {
  try {
    return raw.attributeInstanceGroupedbyName
      .map((attr) => ({
        attributeName: attr.name,
        internalName: attr.internalName,
        objectsCount: attr.objects?.count || 0,
        attachmentsCount: attr.objects?.count || 0,
        dataSourcesCount: attr.datasources?.count || 0,
        ticketsCount: attr.tickets?.count || 0,
        sensitivityLabel: attr.sensitivity,
        attributeInstanceCount: attr.attributeInstanceCount || 0,
        dataSources: attr.datasources?.edges.map(({ node: ds }) => ds)
      }))
      .filter(
        ({ sensitivityLabel }) =>
          !sensitivity || sensitivity.toLocaleLowerCase().includes(sensitivityLabel)
      )
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const queryBlobAttributeCards = (params: IGetDatasourceAttributesParams): string => {
  const attributeFilter =
    params.attribute && params.attribute.length > 0
      ? `{key: ${ATTRIBUTE_FILTER_KEYS.name}, values: ${JSON.stringify(
          params[API_PARAM_ATTRIBUTE]?.split(',')
        )}}`
      : ''

  const filter = attributeFilter ? `, filter: [${attributeFilter}]` : ''

  const columnFilters: FilterParams = {
    filter:
      params.datasourceId && params.datasourceId.length > 0
        ? [{ key: 'DATASOURCE', values: [params.datasourceId] }]
        : []
  }
  const colFilters = parameterizeArrayofObjects(columnFilters?.filter || [])
  let colFilterParams = ''
  if (colFilters) {
    colFilterParams = `, filter:${colFilters}`
  }

  return gql`
    {
      columns(id: "${params?.blobId}"${colFilterParams}) {
        edges {
          node {
            attributeInstanceGroupedbyName(datasourceIds: "${params.datasourceId || ''}"${filter}) {
              edges {
                node {
                  name
                  internalName
                  sensitivity
                  rowCount
                  attributeInstanceCount
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryBlobAttributeCards = (raw: any, sensitivity?: string): AttributeGrouped[] => {
  try {
    return raw?.columns?.edges[0]?.node?.attributeInstanceGroupedbyName?.edges
      .map((attr) => ({
        attributeName: attr.node?.name,
        internalName: attr.node?.internalName,
        sensitivityLabel: attr.node?.sensitivity,
        attributeInstanceCount: attr.node?.attributeInstanceCount || 0,
        rowCount: attr?.node?.rowCount || 0
      }))
      .filter(
        ({ sensitivityLabel }) =>
          !sensitivity || sensitivity.toLocaleLowerCase().includes(sensitivityLabel)
      )
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const querySlackAttributeSummary = ({
  datasourceId,
  filters
}: IGetAttributesFilterParams): string => {
  const filterString = filters?.filter ? parameterizeArrayofObjects(filters.filter) : ''
  let paramString = ''
  if (filterString) {
    paramString += `,filter: ${filterString}`
  }

  return gql`
    {
      attribute(datasourceIds: ["${datasourceId}"] ${paramString}){
        edges {
          node {
            name
            channels {
              count
            }
          }
        }
      }
    }
  `
}

export const mapQuerySlackAttributeSummary = (raw: any): AttributeItem[] => {
  try {
    return raw.attribute.edges.map(({ node: { name = '', channels } }) => ({
      name,
      channelsCount: channels?.count || 0
    }))
  } catch (e) {
    console.error(e)
    return []
  }
}

export const queryAttributeTypeSummary = (): string => {
  return gql`
    {
      attributeSet {
        edges {
          node {
            type
            attributes {
              edges {
                node {
                  name
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryAttributeTypeSummary = (raw: any): AttributeTypeInfo[] => {
  try {
    const list: AttributeTypeInfo[] = []
    raw.attributeSet.edges.forEach(
      ({ node: { type = ATTRIBUTE_SET_TYPES.OTHERS, attributes } }) => {
        attributes.edges.forEach(({ node }) => {
          node.name && list.push({ type, name: node.name })
        })
      }
    )
    return list
  } catch (e) {
    console.error(e)
    return []
  }
}

const createAttributeSetListQuery = (
  params: IGetAttributesTableListParams & IGetAttributesFilterParams
): string => {
  const { page = 1, pageSize = LIMIT_DEFAULT, sensitivity, datasourceIds, ...listFilters } = params
  const cursor = getAfterCursor(page, pageSize)
  let commonParamString = stringifyParams({ sensitivity, datasourceIds })
  const filterString = parameterizeArrayofObjects(listFilters.filter || [])
  if (filterString) {
    commonParamString += `,filter:${filterString}`
  }

  return `
      attributeSet(first: ${pageSize}, after: "${cursor}",${commonParamString}) {
        count
        edges {
          node {
            id
            name
            type
            enabled
            systemDefined
            description
            attributesInstanceCount
            attributes {
              count
            }
            attributesDatasourceCounts {
              count
              datasource {
                edges {
                  node {
                    name
                    type
                    id
                  }
                }
              }
            }
          }
        }
      }
  `
}
export const queryAttributesSetList = (
  params: IGetAttributesTableListParams & IGetAttributesFilterParams
): string => {
  return gql`
    {
      ${createAttributeSetListQuery(params)}
    }
  `
}

export const queryAttributesSetCard = (params: IGetAttributesFilterParams): string => {
  const {
    [FILTER_ATTRIBUTE_DATASOURCEIDS_KEY]: datasourceIds,
    sensitivity,
    ...listFilters
  } = params
  let commonParamString = stringifyParams({ datasourceIds, sensitivity })
  const filterString = listFilters.filter ? parameterizeArrayofObjects(listFilters.filter) : ''
  if (params.id) {
    commonParamString += ',id:"' + params.id + '"'
  }
  if (filterString) {
    commonParamString += `,filter: ${filterString}`
  }
  if (commonParamString) {
    commonParamString = '(' + commonParamString + ')'
  }

  return gql`
    {
      attributeSet${commonParamString}{
        count
        edges {
          node {
            id
            name
            type
            attributesInstanceCount
            enabled
            systemDefined
            attributes {
              count
              edges {
                node {
                  isBeta
                  enabled
                  id
                  name
                  sensitivityLabel
                  systemDefined
                  instanceCount
                }
              }
            }
            attributesDatasourceCounts {
              count
              datasource {
                edges {
                  node {
                    name
                    type
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryAttributesSet = (raw: any): { list: AttributeSet[]; total: number } => {
  const list: AttributeSet[] = raw.attributeSet.edges.map(({ node: attr }) => ({
    id: attr.id,
    name: attr.name,
    enabled: attr.enabled,
    type: attr.type || ATTRIBUTE_SET_TYPES.OTHERS,
    description: attr.description || '',
    owner: attr.systemDefined ? OWNER_TYPE_SYSTEM : OWNER_TYPE_USER,
    attributesInstanceCount: attr.attributesInstanceCount || 0,
    dataSourcesCount: attr.attributesDatasourceCounts?.length || 0,
    attributesCount: attr.attributes?.count || 0,
    attributes:
      attr.attributes?.edges?.map(({ node: attribute }) => {
        return {
          ...attribute,
          owner: attribute.systemDefined ? OWNER_TYPE_SYSTEM : OWNER_TYPE_USER,
          sensitivityLabel: attribute.sensitivityLabel || 'LOW'
        }
      }) || [],
    dataSources:
      (
        attr.attributesDatasourceCounts?.filter(
          (attributesDatasourceCount) =>
            attributesDatasourceCount.datasource &&
            attributesDatasourceCount.datasource.edges.length
        ) || []
      ).map(({ datasource, count: attributesInstanceCount = 0 }) => {
        const { node: dataSource } = datasource.edges[0]
        return {
          ...dataSource,
          attributesInstanceCount,
          owner: dataSource.systemDefined ? OWNER_TYPE_SYSTEM : OWNER_TYPE_USER
        }
      }) || []
  }))

  return { list, total: raw.attributeSet.count || 0 }
}

export const queryAttributesSetListCompact = (): string => {
  return gql`
    {
      attributeSet {
        count
        edges {
          node {
            id
            name
            enabled
            systemDefined
          }
        }
      }
    }
  `
}

export const mapQueryAttributesSetListCompact = (
  raw: any
): { list: AttributeSet[]; total: number } => {
  const list = raw.attributeSet.edges.map(({ node: attribute }) => ({
    id: attribute.id,
    name: attribute.name,
    enabled: attribute.enabled,
    systemDefined: attribute.systemDefined
  }))

  return { list, total: raw.attributeSet.count || 0 }
}

export const queryAttributesAllCard = (params: IGetAttributesFilterParams): string => {
  const {
    [FILTER_ATTRIBUTE_DATASOURCEIDS_KEY]: datasourceIds,
    sensitivity,
    id,
    ...listFilters
  } = params
  let commonParamString = stringifyParams({ datasourceIds, sensitivity })
  if (id) {
    commonParamString += ',id:"' + id + '"'
  }
  const filterString = listFilters.filter ? parameterizeArrayofObjects(listFilters.filter) : ''
  const booleanFilterString = listFilters.booleanFilter
    ? parameterizeArrayofObjects(listFilters.booleanFilter)
    : ''
  if (filterString) {
    commonParamString += `,filter: ${filterString}`
  }
  if (booleanFilterString) {
    commonParamString += `,booleanFilter: ${booleanFilterString}`
  }
  if (commonParamString) {
    commonParamString = '(' + commonParamString + ')'
  }
  return gql`
    {
      attribute${commonParamString}{
        count
        edges {
          node {
            id
            name
            isBeta
            enabled
            internalName
            instanceCount
            sensitivityLabel
            objects{
              count
            }
            attributeSets {
              count
              edges {
                node {
                  id
                  name
                  type
                }
              }
            }
            ${
              window.__featureFlags.accessControl
                ? `userAccess(first: 1) {
              count
            }
            groupAccess(first: 1) {
              count
            }`
                : ''
            }

            datasourceCounts {
              count
              datasource {
                edges {
                  node {
                    id
                    name
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const queryAttributesTopWidget = (params: IGetAttributesFilterParams): string => {
  const {
    [FILTER_ATTRIBUTE_DATASOURCEIDS_KEY]: datasourceIds,
    filter,
    booleanFilter,
    hasInstances,
    sensitivity
  } = params
  let commonParamString = ''

  if (datasourceIds) {
    commonParamString += stringifyParams({ datasourceIds })
  }

  const filterString = filter ? parameterizeArrayofObjects(filter) : ''
  const booleanFilterString = booleanFilter ? parameterizeArrayofObjects(booleanFilter) : ''
  if (filterString) {
    commonParamString += `,filter: ${filterString}`
  }
  if (booleanFilterString) {
    commonParamString += `,booleanFilter: ${booleanFilterString}`
  }
  if (sensitivity) {
    commonParamString += `,sensitivity: ${sensitivity}`
  }
  if (hasInstances) {
    commonParamString += `,hasInstances: true`
  }
  return gql`
    {
      attribute(${commonParamString}){
        edges {
          node {
            id
            name
            internalName
            columnCount
            datasourceCounts {
              count
            }
          }
        }
      }
      attributeWithColumnClassification: attribute(
        ${stringifyParams({ datasourceIds })},
        booleanFilter: [
          {key: ENABLED, value: true},
          {key: HAS_COLUMN_CLASSIFICATION, value: true}
        ],
        ${sensitivity ? `sensitivity: ${sensitivity}` : ''}
      ) {
        edges {
          node {
            id
            name
            internalName
            columnCount
          }
        }
      }
    }
  `
}
export const mapQueryAttributesTopWidget = (
  raw: any
): { topList: AttributeItem[]; topListWithColumnClassification: AttributeItem[] } => {
  const topList = raw.attribute.edges.map(({ node: attribute }) => ({
    id: attribute.id,
    name: attribute.name,
    internalName: attribute.internalName,
    columnCount: attribute.columnCount,
    attributesInstanceCount: attribute.datasourceCounts.reduce((acc, current) => {
      return acc + current.count
    }, 0)
  }))
  const topListWithColumnClassification = raw.attributeWithColumnClassification.edges.map(
    ({ node: attribute }) => ({
      id: attribute.id,
      name: attribute.name,
      internalName: attribute.internalName,
      columnCount: attribute.columnCount
    })
  )

  return { topList, topListWithColumnClassification }
}

export const queryDriveAttributesTopWidget = (params: IAttributeFilterParams): string => {
  const { ...listFilters } = params
  let paramString = ''
  const filterString = listFilters.filter ? parameterizeArrayofObjects(listFilters.filter) : ''
  if (filterString) {
    paramString += `, filter: ${filterString}`
  }

  return gql`
    {
      attributeInstanceGroupedbyName(${paramString}) {
        attributeInstanceCount
        internalName
        name
      }
    }
  `
}

export const mapQueryDriveAttributesTopWidget = (raw: any): { topList: AttributeItem[] } => {
  let topList = []
  if (
    raw &&
    raw.attributeInstanceGroupedbyName &&
    Array.isArray(raw.attributeInstanceGroupedbyName)
  ) {
    topList = raw.attributeInstanceGroupedbyName.map(
      ({ attributeInstanceCount = 0, internalName = '', name = '' }) => ({
        name,
        internalName,
        attributesInstanceCount: attributeInstanceCount
      })
    )
  }
  return { topList }
}

export const queryAttributeslistAll = (): string => {
  return gql`
    {
      attribute {
        count
        edges {
          node {
            id
            name
            internalName
            instanceCount
            attributeSets {
              edges {
                node {
                  type
                }
              }
            }
          }
        }
      }
    }
  `
}
export const queryAttributeCards = (params: IGetAttributesFilterParams): string => {
  const {
    [FILTER_ATTRIBUTE_DATASOURCEIDS_KEY]: datasourceIds,
    sensitivity,
    hasInstances,
    ...listFilters
  } = params
  let commonParamString = stringifyParams({ datasourceIds, sensitivity, hasInstances })
  const filterString = listFilters.filter ? parameterizeArrayofObjects(listFilters.filter) : ''
  if (filterString) {
    commonParamString += `,filter: ${filterString}`
  }
  if (commonParamString) {
    commonParamString = '(' + commonParamString + ')'
  }
  return gql`
    {
      attribute${commonParamString}{
        count
        edges {
          node {
            id
            name
            isBeta
            enabled
            instanceCount
            sensitivityLabel
            systemDefined
            attributeSets(first: 1) {
              count
              edges {
                node {
                  type
                }
              }
            }
            datasourceCounts {
              datasource {
                edges {
                  node {
                    id
                    name
                    type
                  }
                }
              }
            }
            userAccess { count }
          }
        }
      }
    }
  `
}

export const queryAttributesTable = (
  params: IGetAttributesTableListParams & IGetAttributesFilterParams
): string => {
  const {
    page = 1,
    pageSize = LIMIT_DEFAULT,
    sensitivity,
    datasourceIds,
    hasInstances,
    print = false,
    ...listFilters
  } = params
  const cursor = !print && getAfterCursor(page, pageSize)
  let commonParamString = stringifyParams({ sensitivity, datasourceIds })
  if (hasInstances !== undefined) {
    commonParamString += `,hasInstances:${hasInstances}`
  }
  const filterString = parameterizeArrayofObjects(listFilters.filter || [])
  if (filterString) {
    commonParamString += `,filter:${filterString}`
  }
  if (!print) {
    commonParamString += `,after: "${cursor}",first:${pageSize}`
  }

  return gql`
    {
      attribute(${commonParamString}) {
        count
        edges {
          node {
            id
            name
            enabled
            isBeta
            instanceCount
            sensitivityLabel
            systemDefined
            attributeSets { count }
            datasourceCounts {
              datasource {
                edges {
                  node {
                    id
                    name
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}
export const queryAttributesTableSensitivitySorted = (
  params: IGetAttributesTableListParams & IGetAttributesFilterParams
): string => {
  const { page, sensitivity, datasourceIds, hasInstances, print = false, ...listFilters } = params
  const cursor = !print && getAfterCursor(page, LIMIT_DEFAULT)
  let commonParamString = stringifyParams({ sensitivity, datasourceIds })
  if (hasInstances !== undefined) {
    commonParamString += `,hasInstances:${hasInstances}`
  }
  const filterString = parameterizeArrayofObjects(listFilters.filter || [])
  if (filterString) {
    commonParamString += `,filter:${filterString}`
  }
  if (!print) {
    commonParamString += `,after: "${cursor}",first:${LIMIT_DEFAULT}`
  }
  return gql`
    {
      high: attribute(${commonParamString}, sensitivity: HIGH, booleanFilter: [ { key: ENABLED, value: true } { key: IS_CATEGORY, value: false } ]) {
        count
        edges {
          node {
            id
            name
            instanceCount
            sensitivityLabel
            systemDefined
            attributeSets {
              count
            }
            datasourceCounts {
              datasource {
                edges {
                  node {
                    id
                    name
                    type
                  }
                }
              }
            }
          }
        }
      }
      medium: attribute(${commonParamString}, sensitivity: MEDIUM, booleanFilter: [ { key: ENABLED, value: true } { key: IS_CATEGORY, value: false } ]) {
        count
        edges {
          node {
            id
            name
            instanceCount
            sensitivityLabel
            systemDefined
            attributeSets {
              count
            }
            datasourceCounts {
              datasource {
                edges {
                  node {
                    id
                    name
                    type
                  }
                }
              }
            }
          }
        }
      }
      low: attribute(${commonParamString}, sensitivity: LOW, booleanFilter: [ { key: ENABLED, value: true } { key: IS_CATEGORY, value: false } ]) {
        count
        edges {
          node {
            id
            name
            instanceCount
            sensitivityLabel
            systemDefined
            attributeSets {
              count
            }
            datasourceCounts {
              datasource {
                edges {
                  node {
                    id
                    name
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryAttributesListing = (raw: any): { list: AttributeItem[]; total: number } => {
  const list = raw.attribute.edges.map(({ node: attribute }) => ({
    id: attribute.id,
    name: attribute.name,
    isBeta: attribute.isBeta,
    enabled: attribute.enabled,
    types: attribute.attributeSets?.edges ? attribute.attributeSets?.edges[0]?.node?.type : '',
    attributesInstanceCount: attribute.instanceCount || 0,
    partnerAttributeInstanceCount: attribute.partnerAttributeInstance?.count || 0,
    dataSourcesCount: attribute.datasourceCounts?.length || 0,
    attributeId: attribute.id,
    attributeSetsCount: attribute.attributeSets?.count || 0,
    attachmentsCount: attribute.objects?.count || 0,
    userAccessCount: attribute.userAccess?.count || 0,
    groupAccessCount: attribute.groupAccess?.count || 0,
    channelsCount: attribute.channels?.count || 0,
    sensitivityLabel: attribute.sensitivityLabel || 'LOW',
    owner: attribute.systemDefined ? OWNER_TYPE_SYSTEM : OWNER_TYPE_USER,
    attributeSets:
      attribute.attributeSets?.edges?.map(({ node: attributeSet }) => attributeSet) || [],
    dataSources:
      (
        attribute.datasourceCounts?.filter(
          (datasourceCount) => datasourceCount.datasource && datasourceCount.datasource.edges.length
        ) || []
      ).map(({ datasource }) => {
        const { node: dataSource } = datasource.edges[0]
        return {
          ...dataSource
        }
      }) || [],
    objectCount: attribute?.objects?.count || 0
  }))

  return { list, total: raw.attribute.count }
}
export const mapQueryAttributesListingSensitivitySorted = (
  raw: any
): { list: AttributeItem[]; total: number } => {
  const list = [...raw.high.edges, ...raw.medium.edges, ...raw.low.edges].map(
    ({ node: attribute }) => ({
      id: attribute.id,
      name: attribute.name,
      isBeta: attribute.isBeta,
      enabled: attribute.enabled,
      types: attribute.attributeSets?.edges ? attribute.attributeSets?.edges[0]?.node?.type : '',
      attributesInstanceCount: attribute.instanceCount || 0,
      partnerAttributeInstanceCount: attribute.partnerAttributeInstance?.count || 0,
      dataSourcesCount: attribute.datasourceCounts?.length || 0,
      attributeId: attribute.id,
      attributeSetsCount: attribute.attributeSets?.count || 0,
      attachmentsCount: attribute.objects?.count || 0,
      channelsCount: attribute.channels?.count || 0,
      sensitivityLabel: attribute.sensitivityLabel || 'LOW',
      owner: attribute.systemDefined ? OWNER_TYPE_SYSTEM : OWNER_TYPE_USER,
      attributeSets:
        attribute.attributeSets?.edges?.map(({ node: attributeSet }) => attributeSet) || [],
      dataSources:
        (
          attribute.datasourceCounts?.filter(
            (datasourceCount) =>
              datasourceCount.datasource && datasourceCount.datasource.edges.length
          ) || []
        ).map(({ datasource }) => {
          const { node: dataSource } = datasource.edges[0]
          return {
            ...dataSource
          }
        }) || [],
      objectCount: attribute?.objects?.count || 0
    })
  )

  return { list, total: raw.high.count + raw.medium.count + raw.low.count }
}
export const queryListAttributesTable = (
  params: IGetAttributesTableListParams & IGetAttributesFilterParams
): string => {
  const {
    page = 1,
    pageSize = LIMIT_DEFAULT,
    sensitivity,
    datasourceIds = [],
    datasourceType,
    userEntityId,
    alertId,
    MAIL_FOLDER_TYPE,
    filters
  } = params
  const [datasourceId] = datasourceIds
  const isSlack = datasourceType === DATA_SOURCE_TYPES.slack
  const isTeams = datasourceType === DATA_SOURCE_TYPES.teams
  const isJira = datasourceType === DATA_SOURCE_TYPES.jira
  const isAws = datasourceType === DATA_SOURCE_TYPES.aws
  const isGoogleCloudStorage = datasourceType === DATA_SOURCE_TYPES.googleCloudStorage
  const isAzureBlob = datasourceType === DATA_SOURCE_TYPES.azureBlob
  const isGenericDatasource = datasourceType === DATA_SOURCE_TYPES.generic
  const isOutlook = datasourceType === DATA_SOURCE_TYPES.outLook
  const isExchange = datasourceType === DATA_SOURCE_TYPES.exchange
  const isMail = MAIL_DATASOURCES.find((dataSourceType) => datasourceType === dataSourceType)
  const isStructured = STRUCTURED_DATASOURCES.includes(datasourceType as DATA_SOURCE_TYPES)

  const cursor = getAfterCursor(page, pageSize)
  let commonParamString = stringifyParams({ sensitivity, datasourceIds })
  const filterString = parameterizeArrayofObjects(filters?.filter || [])
  if (filterString) {
    commonParamString += `,filter:${filterString}`
  }
  const entityFilter = userEntityId ? `entityId: "${userEntityId}",` : ''
  const alertFilter = alertId ? `, alertId: "${alertId}"` : ''
  // const alertFilter = alertId ? `, alertId: "${alertId}"` : ''
  const paramsString = `first: ${pageSize}, after: "${cursor}",${entityFilter}${alertFilter},${commonParamString}`
  const mailsParams =
    isOutlook && MAIL_FOLDER_TYPE
      ? `{key: MAIL_FOLDER_TYPE, values: ["${MAIL_FOLDER_TYPE}"]}`
      : '[]'
  const dataSourceParam = datasourceId && `(first: 1, id: "${datasourceId}")`
  const FRAGMENT_DATA_SOURCE_TABLES =
    datasourceId && isStructured
      ? `
    datasources${dataSourceParam} {
      edges {
        node {
          id
          name
          type
          tables(first: 999) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }`
      : ``

  return isSlack || isTeams
    ? gql`
    {
      attributeInstance(${paramsString}) {
        edges {
          node {
            id
            name
            values
            updateTimestamp
            attributeSets {
              edges {
                node {
                  type
                  name
                }
              }
            }
            object {
              edges {
                node {
                  messageId
                }
              }
            }
            channel {
              edges {
                node {
                  channelName
                  channelId
                }
              }
            }
          }
        }
      }
    }
  `
    : isMail || isExchange
    ? gql`
    {
      attributeInstance(${paramsString}) {
        edges {
          node {
            id
            name
            values
            attributeSets {
              edges {
                node {
                  type
                  name
                }
              }
            }
            object {
              edges {
                node {
                  name
                  classification
                  mail(filter: ${mailsParams}){
                    edges{
                      node{
                        id
                        type
                        refMailboxIds
                        isDeleted
                        messageId
                        timestamp
                        subject
                        sender {
                          name
                          email
                        }
                        recipients {
                          name
                          email
                        }
                        attachments: objects(category: FILE) {
                          edges {
                            node {
                              id
                              name
                              type
                            }
                          }
                        }
                        userEntities {
                          edges {
                            node {
                              name
                              id
                            }
                          }
                        }
                        objects {
                          edges {
                            node {
                              alert(first: 10, status: ACTIVE_STATUS) {
                                edges {
                                  node {
                                    name
                                    severity
                                    id
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
    : isAws || isAzureBlob || isGoogleCloudStorage
    ? gql`
  {
    attributeInstance(${paramsString}) {
      edges {
        node {
          id
          name
          values
          attributeSets {
            edges {
              node {
                type
                name
              }
            }
          }
          object {
            edges {
              node {
                id
                name
                classification
              }
            }
          }

        }
      }
    }
  }
  `
    : isGenericDatasource
    ? gql`
    {
      attributeInstance(${paramsString}) {
        edges {
          node {
            id
            name
            values
            attributeSets {
              edges {
                node {
                  type
                  name
                }
              }
            }
            object {
              edges {
                node {
                  id
                  name
                  classification
                }
              }
            }
            project {
              edges {
                node {
                  name
                }
              }
            }
          }
        }
      }
    }
  `
    : isJira
    ? `{
      attributeInstance(${paramsString}) {
        edges {
          node {
            id
            name
            values
            attributeSets {
              edges {
                node {
                  type
                  name
                }
              }
            }
            object(datasourceIds:"${datasourceId}") {
              edges {
                node {
                  id
                  name
                  classification
                  ticket {
                    edges {
                      node {
                        id
                        ticketId
                        ticketName
                        lastModifiedTime
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`
    : gql`
    {
      attributeInstance(${paramsString}) {
        edges {
          node {
            id
            name
            values
            sensitivity
            attribute {
              edges {
                node {
                  id
                }
              }
            }
            object {
              edges {
                node {
                  id
                  name
                  classification
                  objectLink
                  metadata {
                    ... on ServiceNowMetadata {
                      incidentNumber
                    }
                  }
                  sharedBy {
                    name
                    email
                  }

                  entity {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
            attributeSets {
              edges {
                node {
                  type
                  name
                }
              }
            }
          }
        }
      }
      ${FRAGMENT_DATA_SOURCE_TABLES}
    }
  `
}
export const mapQueryDataSourceAttributesTable = (
  raw: any,
  blobId = ''
): { list: AttributeItem[]; blobId?: string } => {
  try {
    if (blobId) {
      const list = raw?.columns?.edges[0]?.node?.attributeInstance.edges.map(
        ({ node: { rowId, name, values } }) => ({ rowId, name, identifier: values })
      )
      return { list }
    }

    const datasource = raw.datasources?.edges[0]?.node || {}
    const tables = datasource?.tables?.edges?.map(({ node: table }) => ({ ...table })) || []

    const list = raw.attributeInstance.edges.map(
      ({
        node: {
          id,
          name,
          values,
          object,
          sensitivity,
          channel,
          updateTimestamp,
          attributeSets,
          project,
          attribute
        }
      }) => {
        const tableName = object?.edges[0]?.node?.name || ''
        const tableId = tables?.find(({ name = '' }) => tableName.endsWith(name))?.id || ''

        return {
          id,
          name,
          identifier: values,
          sensitivityLabel: sensitivity || 'LOW',
          fileName: tableName || EMPTY_CONTENT,
          fileClassification:
            object?.edges[0]?.node?.classification || CLASSICATION_TYPE.UNCLASSIFIED,
          fileId: object?.edges[0]?.node?.id || null,
          fileMetadataId: object?.edges[0]?.node?.metadata?.incidentNumber || '',
          objectLink: object?.edges[0]?.node?.objectLink || '',
          updateTimestamp,
          messageId:
            object?.edges[0]?.node?.messageId ||
            object?.edges[0]?.node?.mail?.edges[0]?.node?.messageId ||
            '',
          channelName: channel?.edges[0]?.node?.channelName || '',
          channelId: channel?.edges[0]?.node?.channelId || '',
          attributeSets: attributeSets?.edges?.map(({ node }) => node),
          ticketName: object?.edges[0]?.node?.ticket?.edges[0]?.node?.ticketName || '',
          ticketId: object?.edges[0]?.node?.ticket?.edges[0]?.node?.id || '',
          ticketJiraId: object?.edges[0]?.node?.ticket?.edges[0]?.node?.ticketId || '',
          detectedWhen: object?.edges[0]?.node?.ticket?.edges[0]?.node?.lastModifiedTime || '',
          projectName: project?.edges[0]?.node?.name,
          sharedWhen: object?.edges[0]?.node?.mail?.edges[0]?.node?.timestamp || '',
          isDeleted: object?.edges[0]?.node?.mail?.edges[0]?.node?.isDeleted || false,
          rowId: name + values + object?.edges[0]?.node?.mail?.edges[0]?.node?.id,
          mailFrom: object?.edges[0]?.node?.mail?.edges[0]?.node?.sender?.email || '',
          mailType: object?.edges[0]?.node?.mail?.edges[0]?.node?.type || '',
          attributeId: attribute?.edges[0]?.node?.id || '',
          refMailboxIds: object?.edges[0]?.node?.mail?.edges[0]?.node?.refMailboxIds || [],
          mailTo:
            object?.edges[0]?.node?.mail?.edges[0]?.node?.recipients?.map(
              (recipient) => recipient.email
            ) || [],
          subject: object?.edges[0]?.node?.mail?.edges[0]?.node?.subject,
          attachments:
            object?.edges[0]?.node?.mail?.edges[0]?.node?.attachments?.edges?.map(
              ({ node }) => node
            ) || [],
          alerts: distinctArrayOfObjects(
            (object?.edges[0]?.node?.mail?.edges[0]?.node?.objects?.edges || [])
              .map(({ node }) => node?.alert?.edges?.map(({ node: alert }) => alert))
              .flat(),
            'id'
          ),
          entities:
            object?.edges[0]?.node?.mail?.edges[0]?.node?.userEntities?.edges?.map(({ node }) => ({
              id: node.id,
              name: node.name || []
            })) || [],
          attributeName: name || '',
          objectId: object?.edges[0]?.node?.id || '',
          dataSourceId: datasource?.id || '',
          dataSourceName: datasource?.name || '',
          dataSourceType: datasource?.type || '',
          tableId,
          sharedBy:
            object?.edges[0]?.node?.sharedBy?.name || object?.edges[0]?.sharedBy?.email || '',
          objectEntities:
            object?.edges[0]?.node?.entity?.edges?.map(({ node }) => ({ ...node })) || []
        }
      }
    )

    return { list }
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const queryListBlobAttributesTable = (
  params: IGetAttributesTableListParams & IGetAttributesFilterParams
): string => {
  const {
    page = 1,
    pageSize = LIMIT_DEFAULT,
    sensitivity,
    datasourceIds = [],
    userEntityId,
    alertId,
    filters
  } = params
  const cursor = getAfterCursor(page, pageSize)
  let commonParamString = stringifyParams({ sensitivity, datasourceIds })
  const filterString = parameterizeArrayofObjects(filters?.filter || [])
  if (filterString) {
    commonParamString += `,filter:${filterString}`
  }
  const entityFilter = userEntityId ? `entityId: "${userEntityId}",` : ''
  const alertFilter = alertId ? `, alertId: "${alertId}"` : ''
  // const alertFilter = alertId ? `, alertId: "${alertId}"` : ''
  const paramsString = `first: ${pageSize}, after: "${cursor}",${entityFilter}${alertFilter},${commonParamString}`

  const columnFilters: FilterParams = {
    filter:
      datasourceIds && datasourceIds.length > 0
        ? [{ key: 'DATASOURCE', values: datasourceIds }]
        : []
  }
  const colFilters = parameterizeArrayofObjects(columnFilters?.filter || [])
  let colFilterParams = ''
  if (colFilters) {
    colFilterParams = `, filter:${colFilters}`
  }
  return gql`
    {
      columns(id: "${params?.blobId}"${colFilterParams}) {
        edges {
          node {
            name
            type
            attributeInstance(${paramsString}){
              edges {
                node {
                  name
                  rowId
                  values
                }
              }
            }
          }
        }
      }
    }
    `
}
export const mapQueryListBlobAttributesTable = (
  raw: any,
  blobId = ''
): { list: AttributeItem[]; blobId?: string } => {
  try {
    if (blobId) {
      const list = raw?.columns?.edges[0]?.node?.attributeInstance.edges.map(
        ({ node: { rowId, name, values } }) => ({ rowId, name, identifier: values })
      )
      return { list }
    }
    const list = raw.attributeInstance.edges.map(
      ({
        node: {
          id,
          name,
          values,
          object,
          sensitivity,
          channel,
          updateTimestamp,
          attributeSets,
          project,
          attribute,
          datasource
        }
      }) => ({
        id,
        name,
        identifier: values,
        sensitivityLabel: sensitivity || 'LOW',
        fileName: object?.edges[0]?.node?.name || EMPTY_CONTENT,
        fileClassification:
          object?.edges[0]?.node?.classification || CLASSICATION_TYPE.UNCLASSIFIED,
        fileId: object?.edges[0]?.node?.id || null,
        fileMetadataId: object?.edges[0]?.node?.metadata?.incidentNumber || '',
        objectLink: object?.edges[0]?.node?.objectLink || '',
        updateTimestamp,
        messageId:
          object?.edges[0]?.node?.messageId ||
          object?.edges[0]?.node?.mail?.edges[0]?.node?.messageId ||
          '',
        channelName: channel?.edges[0]?.node?.channelName || '',
        channelId: channel?.edges[0]?.node?.channelId || '',
        attributeSets: attributeSets?.edges?.map(({ node }) => node),
        ticketName: object?.edges[0]?.node?.ticket?.edges[0]?.node?.ticketName || '',
        ticketId: object?.edges[0]?.node?.ticket?.edges[0]?.node?.id || '',
        ticketJiraId: object?.edges[0]?.node?.ticket?.edges[0]?.node?.ticketId || '',
        detectedWhen: object?.edges[0]?.node?.ticket?.edges[0]?.node?.lastModifiedTime || '',
        projectName: project?.edges[0]?.node?.name,
        sharedWhen: object?.edges[0]?.node?.mail?.edges[0]?.node?.timestamp || '',
        isDeleted: object?.edges[0]?.node?.mail?.edges[0]?.node?.isDeleted || false,
        rowId: name + values + object?.edges[0]?.node?.mail?.edges[0]?.node?.id,
        mailFrom: object?.edges[0]?.node?.mail?.edges[0]?.node?.sender?.email || '',
        attributeId: attribute?.edges[0]?.node?.id || '',

        mailTo:
          object?.edges[0]?.node?.mail?.edges[0]?.node?.recipients?.map(
            (recipient) => recipient.email
          ) || [],
        subject: object?.edges[0]?.node?.mail?.edges[0]?.node?.subject,
        attachments:
          object?.edges[0]?.node?.mail?.edges[0]?.node?.attachments?.edges?.map(
            ({ node }) => node
          ) || [],
        alerts: distinctArrayOfObjects(
          (object?.edges[0]?.node?.mail?.edges[0]?.node?.objects?.edges || [])
            .map(({ node }) => node?.alert?.edges?.map(({ node: alert }) => alert))
            .flat(),
          'id'
        ),
        entities:
          object?.edges[0]?.node?.mail?.edges[0]?.node?.userEntities?.edges?.map(({ node }) => ({
            id: node.id,
            name: node.name || []
          })) || [],
        attributeName: name || '',
        objectId: object?.edges[0]?.node?.id || '',
        dataSourceId: datasource?.edges[0]?.node?.id || '',
        dataSourceName: datasource?.edges[0]?.node?.name || '',
        dataSourceType: datasource?.edges[0]?.node?.type || '',
        tableId: datasource?.edges[0]?.node?.tables?.edges[0]?.node?.id || ''
      })
    )

    return { list }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAwsAttributesTable = (
  params: IGetAttributesTableListParams & IGetAttributesFilterParams
): string => {
  const { page, userEntityId, [DATA_SOURCE_ID]: datasourceId, ...listFilters } = params
  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  const filterDatasource = datasourceId ? `, datasourceIds: ${JSON.stringify([datasourceId])}` : ''
  const filterEntity = userEntityId ? `, entityId: "${userEntityId}"` : ''
  let commonParamString = ''
  const filterString = parameterizeArrayofObjects(listFilters.filter || [])
  if (filterString) {
    commonParamString += `,filter:${filterString}`
  }
  return gql`
  {
    attributeInstance(first: ${LIMIT_DEFAULT}, after: "${cursor}"${filterDatasource}${filterEntity}${commonParamString}) {
      count
      edges {
        node {
          id
          name
          values
          object {
            edges {
              node {
                id
                name
                classification
              }
            }
          }

        }
      }
    }
  }
  `
}

export const mapQueryAwsAttributesTable = (raw: any): AttributeTableResponse => {
  try {
    const list = raw.attributeInstance.edges.map(({ node: { id, name, values, object } }) => ({
      id,
      name: object?.edges[0]?.node.name || '',
      identifier: values,
      attributeName: name,
      objectId: object?.edges[0]?.node.id || '',
      fileClassification: object?.edges[0]?.node.classification || CLASSICATION_TYPE.UNCLASSIFIED
    }))

    return { list, total: raw.attributeInstance.count }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAttributeNamesIds = (): string => {
  return gql`
    {
      attribute(booleanFilter: [{ key: ENABLED, value: true }]) {
        edges {
          node {
            id
            name
            internalName
            enabled
            sensitivityLabel
          }
        }
      }
    }
  `
}

export const queryAttributeNames = (): string => {
  return gql`
    {
      attribute {
        edges {
          node {
            name
          }
        }
      }
    }
  `
}

export const mapQueryAttributeNames = (raw: any): { list: string[] } => {
  const list = raw.attribute.edges.map(({ node: { name } }) => name)

  return { list: [...new Set<string>(list)] }
}

export const mapQueryAttributeNameIds = (raw: any): { list: AttributeNameIds[] } => {
  const list = raw.attribute.edges.map(
    ({ node: { name: attrName, internalName, id: attrId, enabled, sensitivityLabel } }) => ({
      name: attrName,
      internalName,
      id: attrId,
      enabled,
      sensitivityLabel
    })
  )

  return { list: [...new Set<AttributeNameIds>(list)] }
}

export const queryAttributeItemNameById = (id: string, key: string): string => {
  return gql`
    {
      ${key}(id: "${id}") {
        edges {
          node {
            name
          }
        }
      }
    }
  `
}

export const mapAttrubuteItemNameById = (raw: any, key: string): string => {
  const [attributeItem] = raw[key].edges
  return attributeItem?.node?.name || ''
}

export const mapQueryAttributesAll = (raw: any): { list: AttributeItem[]; total: number } => {
  const list = raw.attribute.edges.map(({ node: attr }) => ({
    id: attr.id,
    name: attr.name,
    isBeta: attr.isBeta,
    enabled: attr.enabled,
    internalName: attr.internalName,
    types: [...new Set(attr.attributeSets?.edges?.map(({ node }) => node.type) || [])],
    attributesInstanceCount: attr.instanceCount || 0,
    attributeSetsCount: attr.attributeSets?.count || 0,
    attachmentsCount: attr.objects?.count || 0,
    sensitivityLabel: attr.sensitivityLabel || 'LOW',
    owner: attr.systemDefined ? OWNER_TYPE_SYSTEM : OWNER_TYPE_USER,
    dataSourcesCount: attr.datasourceCounts?.length || 0,
    userAccessCount: attr.userAccess?.count || 0,
    groupAccessCount: attr.groupAccess?.count || 0,
    attributeSets: attr.attributeSets?.edges?.map(({ node: attributeSet }) => attributeSet) || [],
    dataSources:
      (
        attr.datasourceCounts?.filter(
          (datasourceCount) => datasourceCount.datasource && datasourceCount.datasource.edges.length
        ) || []
      ).map(({ datasource, count: attributesInstanceCount = 0 }) => {
        const { node: dataSource } = datasource.edges[0]
        return {
          ...dataSource,
          attributesInstanceCount,
          owner: dataSource.systemDefined ? OWNER_TYPE_SYSTEM : OWNER_TYPE_USER
        }
      }) || []
  }))

  return { list, total: raw.attribute.count }
}

export const mapQueryAttributesListAll = (
  raw: any
): { list: AttributeListAll[]; total: number } => {
  const list = raw.attribute.edges.map(({ node: attr }) => ({
    id: attr.id,
    name: attr.name,
    internalName: attr.internalName,
    types: [...new Set(attr.attributeSets?.edges?.map(({ node }) => node.type) || [])],
    attributesInstanceCount: attr.instanceCount || 0
  }))

  return { list, total: raw.attribute.count }
}
export const queryMutationCreateAttributeSet = (params: CreateAttributeSetParams): string => {
  return gql`mutation {
    createAttributeSet( clientMutationId: "createAttributeSet"
    attributeSetData: {
      name: "${params.name}"
      description: "${escapeNewlineandTab(params.description)}"
      type: ${params.type}
      ${params.attributes ? `attributes: ${JSON.stringify(params.attributes)}` : ''}
    })
    {
      clientMutationId
    }}
  `
}
export const queryMutationUpdateAttributeSet = (params: UpdateAttributeSetParams): string => {
  const { clientMutationId, id } = params
  return gql`
  mutation {
    updateAttributeSet(
      clientMutationId: "${clientMutationId}",
      id: "${id}",
      attributeSetData: {
        name: "${params.name}"
        description: "${params.description}"
        type: ${params.type}
        attributes: ${JSON.stringify(params.attributes)}
      }){
        attributeSet{
          edges{
            node{
              id
              name
            }
          }
        }
      }
    }
  `
  // mutation{updateAttributeSet(clientMutationId: "a" id: "id" attributeSetData:{name:"name"}){
  //   attributeSet{
  //     edges{
  //       node{
  //         id
  //         name
  //       }
  //     }
  //   }
  // }}
}
export const queryMutationSetAttributeSetActive = (params: setActiveMutationParam): string => {
  const { clientMutationId, id, enabled } = params
  return gql`
    mutation {
      updateAttributeSet(clientMutationId: "${clientMutationId}", id: "${id}", attributeSetData: { enabled: ${enabled} }) {
        clientMutationId
        attributeSet {
          edges {
            node {
              id
              enabled
            }
          }
        }
      }
    }
  `
}

export const mapQueryMutationCreateAttributeSet = (
  raw: any
): { list: { id: string; enabled: boolean } } => {
  try {
    const list = raw.updateAttributeSet.attributeSet.edges.map(({ node: attr }) => ({
      id: attr.id,
      enabled: attr.enabled
    }))[0]
    return { list }
  } catch (e) {
    console.error(e)
    return { list: { id: '', enabled: false } }
  }
}

export const queryAttributeInstancesCount = (params: IGetAttributeInstancesCountParams): string => {
  const { entityId, datasourceIds, sensitivity, alertId, subAlertAssignees = [] } = params
  const filters = parameterizeArrayofObjects(params.filters?.filter || [])
  let filterParams = ''
  if (filters) {
    filterParams = `filter:${filters}`
  }
  const entityFilter = entityId ? `, entityId: "${entityId}"` : ''
  const alertFilter = alertId ? `, alertId: "${alertId}"` : ''
  const datasourceIdFilter = datasourceIds ? `, datasourceIds: "${datasourceIds}"` : ''
  const sensitivityFilter = sensitivity ? `, sensitivity: ${sensitivity}` : ''
  const filterSubAlertAssignees =
    subAlertAssignees.length > 0 ? `, subAlertAssignees: ${JSON.stringify(subAlertAssignees)}` : ''
  const hasFilters =
    entityId || datasourceIds || filterParams || sensitivity || filterSubAlertAssignees
  return gql`
  {
    attributeInstance${
      hasFilters
        ? `(${filterParams}${entityFilter}${datasourceIdFilter}${sensitivityFilter}${alertFilter}${filterSubAlertAssignees})`
        : ''
    } {
      count
    }
  }
`
}

export const mapQueryAttributeInstancesCount = (raw: any): { count: number } => {
  try {
    return {
      count: raw.attributeInstance?.count || 0
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryBlobAttributeInstancesCount = (
  params: IGetAttributeInstancesCountParams
): string => {
  const { datasourceIds, sensitivity, blobId } = params
  const filters = parameterizeArrayofObjects(params?.filters?.filter || [])
  let filterParams = ''
  if (filters) {
    filterParams = `filter:${filters}`
  }

  const columnFilters: FilterParams = {
    filter:
      datasourceIds && datasourceIds.length > 0
        ? [{ key: 'DATASOURCE', values: datasourceIds }]
        : []
  }
  const colFilters = parameterizeArrayofObjects(columnFilters?.filter || [])
  let colFilterParams = ''
  if (colFilters) {
    colFilterParams = `, filter:${colFilters}`
  }

  const datasourceIdFilter = datasourceIds ? `, datasourceIds: "${datasourceIds}"` : ''
  const sensitivityFilter = sensitivity ? `, sensitivity: ${sensitivity}` : ''
  const hasFilters = datasourceIds || filterParams || sensitivity
  return gql`
  {
    columns(id: "${blobId}"${colFilterParams}) {
      edges {
        node {
          attributeInstance${
            hasFilters ? `(${filterParams}${datasourceIdFilter}${sensitivityFilter})` : ''
          } {
            count
          }
        }
      }
    }
  }
`
}

export const mapQueryBlobAttributeInstancesCount = (raw: any): { count: number } => {
  try {
    return {
      count: raw.columns?.edges[0]?.node?.attributeInstance?.count || 0
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAttributesSetWithAttributes = (): string => {
  return gql`
    {
      attributeSet {
        count
        edges {
          node {
            id
            name
            attributes {
              count
              edges {
                node {
                  id
                  name
                  sensitivityLabel
                  createdBy
                  attributeSets {
                    count
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryAttributesSetWithAttributes = (
  raw: any
): { list: SetWithAttributes[]; total: number } => {
  const total = raw.attributeSet?.count || 0
  try {
    const list = raw.attributeSet?.edges.map(
      ({ node: { name = '', id: attributeSetId = '', attributes } }) => {
        return {
          id: attributeSetId,
          name,
          isSelected: false,
          isExpanded: false,
          attributesCount: attributes?.count || 0,
          attributes:
            attributes.edges.map(
              ({
                node: {
                  name = '',
                  id = '',
                  createdBy = '',
                  sensitivityLabel = 'LOW',
                  attributeSets
                }
              }) => ({
                id,
                name,
                sensitivityLabel,
                owner: createdBy,
                attributeSetId,
                isSelected: false,
                attributeSetsCount: attributeSets?.count || 0
              })
            ) || 0
        }
      }
    )
    return { list, total }
  } catch (e) {
    console.error(e)
    return { list: [], total: 0 }
  }
}

export const queryAttributesSetSummaryWithAttributes = (): string => {
  return gql`
    {
      attributeSet {
        edges {
          node {
            id
            name
            type
            attributes {
              count
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryAttributesSetSummaryWithAttributes = (raw: any): SetWithAttributes[] => {
  try {
    const list = raw.attributeSet?.edges.map(
      ({ node: { name = '', type, id: attributeSetId = '', attributes } }) => {
        return {
          id: attributeSetId,
          name,
          type,
          attributesCount: attributes?.count || 0,
          attributes:
            attributes.edges.map(({ node: { name = '', id = '' } }) => ({
              id,
              name
            })) || []
        }
      }
    )
    return list
  } catch (e) {
    console.error(e)
    return []
  }
}

export const queryDeleteAttributesSet = (params: DeleteAttributesSetParams): string => {
  return gql`
    mutation {
      deleteAttributeSet(clientMutationId: "${params.clientMutationId}", id: "${params.id}") {
        clientMutationId
        message
      }
    }
  `
}

export const queryAttributeCondition = (params: AttributeConditionParams): string => {
  return gql`
    {
      alert(first: 1, id: "${params.alertId}") {
        edges {
          node {
            policy {
              edges {
                node {
                  attributeCondition {
                    sensitivity
                    attributeCount
                    conditionOperator
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryAttributeCondition = (raw: any): AlertAttributeCondition[] => {
  try {
    const attributeCondition =
      raw.alert?.edges[0]?.node?.policy?.edges[0]?.node?.attributeCondition || []

    return attributeCondition.map((condition) => ({
      sensitivity: condition?.sensitivity || '',
      attributeCount: condition?.attributeCount || 0,
      conditionOperator: condition?.conditionOperator || null
    }))
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const queryAttributeDataSources = (params: AttributeDetailsParams): string => {
  const { attributeId } = params

  const filterAttribute = attributeId ? `attributeId: "${attributeId}",` : ''
  return gql`
    {
      attributeGroupByDatasource(first: 100, ${filterAttribute}) {
        edges {
          node {
            datasourceId
            attributesCount
            datasource {
              name
              type
            }
            userAccess(first: 1) { count }
            groupAccess(first: 1) { count }
          }
        }
      }
    }
  `
}
export const mapQueryAttributeDataSources = (raw: any): AttributeDataSourceInfo[] => {
  try {
    return raw.attributeGroupByDatasource.edges.map(({ node }) => ({
      id: node?.datasourceId,
      name: node?.datasource?.name || '',
      type: node?.datasource?.type || '',
      attributesInstanceCount: node?.attributesCount || 0,
      owner: 'user',
      userAccessCount: node?.userAccess?.count || 0,
      groupAccessCount: node?.groupAccess?.count || 0
    }))
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAttributeUserAccess = (params: AttributeDetailsParams): string => {
  const { attributeId } = params
  const filters = getUserFiltersString(params, { hasPagination: true })

  return gql`
    {
      attribute(first: 1, id: "${attributeId}") {
        edges {
          node {
            userAccess(${filters}) {
              count
              edges {
                node {
                  id
                  name
                  dataPrivilegeLevel
                  employeeType
                  departmentName
                  datasources { count }
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryAttributeUserAccess = (
  raw: any
): { list: AttributeUserAccess[]; total: number } => {
  try {
    const list = raw.attribute?.edges[0]?.node?.userAccess?.edges?.map(({ node }) => ({
      id: node.id,
      name: node.name,
      employeeType: node.employeeType,
      dataPrivilegeLevel: node.dataPrivilegeLevel,
      departmentName: node.departmentName,
      dataSourcesCount: node.datasources?.count
    }))

    return { list, total: raw.attribute?.edges[0]?.node?.userAccess?.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAttributeUserAccessGroupByDepartment = (params: AttributeDetailsParams) => {
  const filters = getUserFiltersString(
    { ...params, attributeIds: [params.attributeId] },
    { hasPagination: false }
  )

  return gql`
    {

      accessControlUsersGroupedByDept(${filters}) {
        count
        edges {
          node {
            departmentName
            memberUsers {
              count
            }
            dataPrivilegedMembers: memberUsers(sensitivity:[${DataSensitivityLevels.High}]) {
              count
            }
          }
        }
      }
    }
  `
}

export const mapQueryAttributeUserAccessGroupByDepartment = (
  raw: any
): { cards: AccessControlUserGroupByDepartmentItem[]; total: number } => {
  const { accessControlUsersGroupedByDept } = raw
  const cards = accessControlUsersGroupedByDept.edges.map(({ node }) => ({
    departmentName: node?.departmentName,
    memberCount: node?.memberUsers?.count || 0,
    dataPrivilegeMemberCount: node?.dataPrivilegedMembers?.count || 0
  }))

  return {
    cards,
    total: accessControlUsersGroupedByDept?.count || 0
  }
}

export const queryAttributeGroupAccess = (params: AttributeDetailsParams): string => {
  const { attributeId } = params
  const filters = getUserFiltersString(params, { hasPagination: true })

  return gql`
    {
      attribute(first: 1, id: "${attributeId}") {
        edges {
          node {
            groupAccess(${filters}) {
              count
              edges {
                node {
                  id
                  name
                  dataPrivilegeLevel
                  memberUsers {
                    count
                  }
                  memberGroups {
                    count
                  }
                  datasources {
                    count
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryAttributeGroupAccess = (
  raw: any
): { list: AttributeGroupAccess[]; total: number } => {
  try {
    const list = raw.attribute?.edges[0]?.node?.groupAccess?.edges?.map(({ node }) => ({
      id: node.id,
      name: node.name,
      dataPrivilegeLevel: node.dataPrivilegeLevel,
      usersCount: node.memberUsers?.count,
      groupsCount: node.memberGroups?.count,
      dataSourcesCount: node.datasources?.count
    }))

    return { list, total: raw.attribute?.edges[0]?.node?.groupAccess?.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

function escapeForRegex(input: string): string {
  return input.replace(/\\/g, '\\\\')
}

const getParamsFragmentForCustomAttribute = (params: CustomAttributeData, forEdit?: boolean) => {
  const { basicDetails, detection } = params
  const isKeyDetection = detection.detectionType === DetectionType.KeyValue
  const noPatterns = !detection.showValues
  const contextFragment = !isArrayEmptyOrFalsy(detection.contextWords)
    ? `context: [${detection.contextWords
        .map(
          (word) =>
            `{ keyword: "${word}" ${
              isKeyDetection && noPatterns ? `, strength: ${RegexType.High}` : ''
            } }`
        )
        .join(',')}]`
    : ''

  const patternsFragment = noPatterns
    ? `patterns: []`
    : `patterns: [${detection.regexValues
        .map(
          (value) =>
            `{ regex: "${escapeForRegex(value)}", strength: ${
              isKeyDetection ? RegexType.Key : RegexType.Medium
            } }`
        )
        .join(',')}]`

  const basicDetailsFragment = `
    name: "${basicDetails.name}"
    ${forEdit ? '' : `sensitivityLabel: ${basicDetails.sensitivity}`}
   ${
     basicDetails.attributeSetIds?.length
       ? `attributeSets: ${JSON.stringify(basicDetails.attributeSetIds)}`
       : ''
   }
  `

  return { contextFragment, patternsFragment, basicDetailsFragment }
}

export const createCustomAttributeMutation = (params: CustomAttributeData) => {
  const {
    contextFragment,
    patternsFragment,
    basicDetailsFragment
  } = getParamsFragmentForCustomAttribute(params)
  return gql`
  mutation {
    createAttribute(
      attributeData: {
        ${basicDetailsFragment}
        ${contextFragment}
        ${patternsFragment}
      }
      clientMutationId: "1"
    ) {
      clientMutationId
      attribute {
        edges {
          node {
            id
          }
        }
      }
    }
  }
  `
}

export const updateCustomAttributeMutation = (params: CustomAttributeData) => {
  const {
    contextFragment,
    patternsFragment,
    basicDetailsFragment
  } = getParamsFragmentForCustomAttribute(params, true)
  return gql`
  mutation {
    updateAttribute(
      id: "${params.id}"
      attributeData: {
        ${basicDetailsFragment}
        ${contextFragment}
        ${patternsFragment}
      }
      clientMutationId: "1"
    ) {
      clientMutationId
      attribute {
        edges {
          node {
            id
          }
        }
      }
    }
  }
  `
}

export const assignAttributeSetsMutation = (params: CustomAttribute): string => {
  return gql`
    mutation {
      updateAttribute(
        id: "${params.id}"
        attributeData: {
          attributeSets: ${JSON.stringify(params.attributeSetIds)}
          enableMasking: true
          name: "${params.name}"
          ${
            params?.patterns?.length
              ? `
           patterns: [${params.patterns
             .map(
               (item) => `{regex: "${escapeForRegex(item.regex)}"
           ${item.strength ? `, strength: ${item.strength}` : ''}
          }
           `
             )
             .join(',')}]
            `
              : 'patterns: []'
          }
          ${
            params.context?.length
              ? `
            context: [${params.context
              .map(
                (item) => `{keyword: "${item.keyword}"
            ${item.strength ? `, strength: ${item.strength}` : ''}
          }`
              )
              .join(',')}]
            `
              : ''
          }
        }
        clientMutationId: "1"
        ) {
        clientMutationId
        attribute {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  `
}

export const queryCustomAttributes = (): string => {
  return gql`
    {
      attribute(systemDefined: false) {
        edges {
          node {
            id
            name
            enabled
            sensitivityLabel
            attributeSets {
              edges {
                node {
                  name
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryCustomAttributes = (raw: any): CustomAttributeListItem[] => {
  return raw.attribute.edges.map(({ node }) => ({
    name: node.name,
    id: node.id,
    enabled: node.enabled,
    sensitiveLabel: node.sensitivityLabel,
    attributeSets: node.attributeSets.edges.map(({ node }) => node.name)
  }))
}

export const queryCustomAttributeById = (id: string): string => {
  return gql`
    {
      attribute(id: "${id}") {
        edges {
          node {
            id
            name
            enabled
            sensitivityLabel
            context {
              keyword
              strength
            }
            patterns {
              regex
              strength
            }
            attributeSets {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }

  `
}

export const mapQueryCustomAttributeById = (raw: any): CustomAttribute => {
  return {
    ...raw.attribute.edges[0]?.node,
    attributeSetIds: raw.attribute.edges[0]?.node?.attributeSets.edges.map(({ node }) => node.id)
  }
}

export const queryAttributesForOnDemandScanning = (): string => {
  return gql`
    {
      attributeSet {
        edges {
          node {
            id
            name
            enabled
            attributes {
              edges {
                node {
                  id
                  name
                  instanceCount
                  sensitivityLabel
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryAttributesForOnDemandScanning = (
  raw: any
): { attributeSets: AttributeSet[]; attributes: AttributeSetAttribute[] } => {
  try {
    const distinctAttributes = {}
    const attributeSets = raw.attributeSet.edges
      .filter(({ node: attrSet }) => attrSet.enabled)
      .map(({ node: attrSet }) => {
        let attributesInstanceCount = 0
        const attributes =
          attrSet.attributes?.edges?.map(({ node: attr }) => {
            const instanceCount = attr.instanceCount
            attributesInstanceCount += instanceCount
            const attribute = {
              id: attr.id,
              name: attr.name,
              sensitivityLabel: attr.sensitivityLabel,
              instanceCount
            }
            distinctAttributes[attr.id] = attribute
            return attribute
          }) || []

        return {
          id: attrSet.id,
          name: attrSet.name,
          enabled: attrSet.enabled,
          description: attrSet.description || '',
          type: attrSet.type || '',
          attributesInstanceCount,
          attributesCount: attrSet.attributes?.count || 0,
          attributes
        }
      })

    const attrs = Object.values(distinctAttributes) as AttributeSetAttribute[]
    const sortMapper = (a, b) =>
      (b.datasources?.reduce((total, ds) => (total += ds?.count || 0), 0) || 0) -
      (a.datasources?.reduce((total, ds) => (total += ds?.count || 0), 0) || 0)
    const highSensitiveAttrs = attrs
      .filter(({ sensitivityLabel }) => sensitivityLabel === SENSITIVE_LABEL.HIGH)
      .sort(sortMapper)
    const mediumSensitiveAttrs = attrs
      .filter(({ sensitivityLabel }) => sensitivityLabel === SENSITIVE_LABEL.MEDIUM)
      .sort(sortMapper)
    const lowSensitiveAttrs = attrs
      .filter(({ sensitivityLabel }) => sensitivityLabel === SENSITIVE_LABEL.LOW)
      .sort(sortMapper)

    return {
      attributeSets,
      attributes: [...highSensitiveAttrs, ...mediumSensitiveAttrs, ...lowSensitiveAttrs]
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}
