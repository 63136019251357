/**
 * Swagger FastComply
 * Spec for Fastcomply APIs >
 * ## Authentication  This API uses Cookie authentication.
 * ## Status codes  This API uses HTTP status codes to communicate with the API consumer.
 * + `200 OK` - Response to a successful GET, PUT, PATCH or DELETE.
 * + `201 Created` - Response to a POST that results in a creation.
 * + `202 Accepted` - The request has been accepted for processing, but the processing has not been completed.
 * + `204 No Content` - Response to a successful request that won\'t be returning a body (like a DELETE request).
 * + `302 Found` - Tells the client to look at (browse to) another url.
 * + `304 Not Modified` - Response has not been modified since the previous transmission.
 * + `400 Bad Request` - Malformed request; request body validation errors.
 * + `401 Unauthorized` - When no or invalid authentication details are provided.
 * + `402 Payment required` - When payment check has failed
 * + `403 Forbidden` - When authentication succeeded but authenticated user doesn\'t have access to the resource.
 * + `404 Not Found` - When a non-existent resource is requested.
 * + `405 Method Not Allowed` - Method not allowed.
 * + `409 Conflict` - When the request could not be completed due to a conflict with the current state of the resource.
 * + `422 Unprocessable Entity` - The request was well-formed but was unable to be followed due to semantic errors.
 * + `500 Server Error` - Something went wrong on the API end.
 * + `501 Not Implemented` - The server either does not recognize the request method, or it lacks the ability to fulfill the request.
 *
 * ## Roles  Each API endpoint has described minimal role to access. Only users with specific roles can access those endpoints.
 * ## For insufficient role `403 Forbidden` HTTP response will be returned.
 * ## User roles:
 * + `1 (unconfirmed)` - registered user with not confirmed e-mail address
 * + `2 (confirmed)` - registered user with confirmed e-mail address
 * + `3 (admin)` - user who can have access to the whole system
 *
 * ## Workflows
 *
 * ### 1) Register/ Login workflow
 * Look at apis with tags `Authentication`
 * 1) User registers on the portal with `/api/auth/register`
 * 2) User logs in with `/api/auth/login`, this sets a session cookie, that can be used during the whole session for subequent requests.
 * 3) `/api/auth/logout` logs out the user
 *
 * ### 2) Data sources. Look at apis with tags `datasources`
 * 1) To see all possible types of datasources and their configuration keys call `/api/configurations/datasources`.
 * This way UI client knows which keys to show corresponding to the datasource.
 * 2) To see the existing set of configured datasources call GET `/api/datasources`
 * 3) To test the connection call `/api/diagnostics/testconnection`.
 * 4) To create a new datasource instance call POST `/api/datasources`
 *
 * ### 3) Dashboard  TODO
 * ### 4) Annotations  Look at apis with tags `annotations`
 * 1) To see all different annotations specifically the system generated as well as user created annotations call GET `/api/templates`.
 * 2) In order to create a new annotation flow call POST `/api/templates`.
 * 3) In order to see documents associated with an annotation call GET `/api/documents`.
 * It takes a query param with annotationId to filter documents for the specific annotation.
 * The response has the document details  as well as the tags discovered for the document, along with additional metadata
 * such as whether the document is a medical document and other compliance details like CCPA, GDPR etc.
 * 4) Next call the ` /api/files/annotate/{documentId}` endpoint to stream the file.
 * 5) To fetch tags for a specific document call `/api/documents/{docId}`.
 * The tags have the bounding box coordinates, name and value as well as confidence level.
 * 6) To fetch the label to give the tags call the GET `/api/identifiers` endpoint.
 * 7) To define new tags call POST `/api/documents` that takes the boundingbox information along with
 * the documentId and annotationId to associate the tag with.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abhinay@fastcomply.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  DataSourcesConfigurations,
  DATASOURCE_STATUS,
  DATA_SOURCE_TYPES,
  DropboxOnboardingMethod
} from '../../constants'
import { ContainerAccess } from '../../features/buckets/bucketsSlice'
import { scanOptions } from '../../features/dataSources/components/UnstructuredForm/GSuite/gSuiteSettings'
import {
  DataSourcesCredentials,
  DS_REGION,
  DS_WORKGROUP,
  SotOption,
  StructuredScanConditions
} from '../../features/dataSourcesMixed/slice'
import { UnstructuredScanConditions } from '../../interfaces'
import { ISharePointFields } from '../../features/dataSources/components/UnstructuredForm/SharePoint/sharePointSettings'
import { DocumentLabel } from '../../features/documentLabelsV2/types'
import { DOCUMENT_LABEL_PROVIDERS } from '../../features/documentLabelsV2/contants'
import { DAY_OF_WEEK, FREQUENCY_TYPES } from '../../features/accessControl/utils/constants'
import { ConnectionStatsTabsV2FilterType } from '../../features/databases/databasesConnectionStatsList'

/** Manual change start  */
export interface SensitiveAttributes {
  attributeName: string

  documentId: string

  documentName: string

  datasourceId: string

  datasourceName: string

  datasourceType: string

  documentType: string
}
/** Manual change end */

export enum DOC_ANALYTICS_SCAN_CONDITIONS_TYPE {
  allFiles = 'SCAN_ALL',
  selectedFiles = 'SYNC_DATASOURCE_SCAN_CONDITIONS'
}

export interface DocAnalyticsConfiguration {
  enabled: boolean
  schedule: {
    type?: string
    frequencyType: string
    frequencyValue: string
  }
  scanConditionsType: string
}

type DataSourcesAwsSettings = {
  [DS_REGION]: string
  [DS_WORKGROUP]: string
}

export type DataSourceConfigurationDetail = {
  name?: string
  location?: string
  flavour?: string
  datasourceType: string
  salesforceConfiguration?: ConfigSalesforce
  awsS3Configuration?: ConfigAWS
  googleCloudStorageConfiguration?: ConfigGoogleCloudStorage
  azureBlobConfiguration?: ConfigAzureBlob
  gsuiteConfiguration?: ConfigGSuite
  gmailConfiguration?: ConfigGSuite
  slackConfiguration?: ConfigSlack
  jiraConfiguration?: ConfigJira
  elasticsearchConfiguration?: ConfigElasticSearch
  microsoftOfficeConfig?: ConfigMsOffice
  boxConfiguration?: ConfigBox
  dropboxConfiguration?: ConfigDropbox
  smbConfiguration?: ConfigSmb
  smb_configuration?: any
  [DataSourcesConfigurations.snowflake]?: DataSourcesCredentials
  [DataSourcesConfigurations.mssql]?: DataSourcesCredentials
  [DataSourcesConfigurations.mysql]?: DataSourcesCredentials
  [DataSourcesConfigurations.postgresql]?: DataSourcesCredentials
  [DataSourcesConfigurations.glue]?: DataSourcesCredentials & DataSourcesAwsSettings
  labels?: DocumentLabel[]
}
export interface DataSource {
  configuration: DataSourceConfigurationDetail
  labels?: DocumentLabel[]
  sotConfiguration?: SotOption[]
  scanSpecificDomains?: string[]
  canCreateEntities?: boolean
  isSourceOfTruth?: boolean
  oauthMethod?: DropboxOnboardingMethod
  scanConditions?: UnstructuredScanConditions[] | StructuredScanConditions[]
  scanSettings?: scanOptions
  dataSourceType?: DATA_SOURCE_TYPES
  dataSourceName?: string
  isRisky?: boolean
  id?: string
  name?: string
  type?: string
  coordinates?: Coordinates
  numberOfObjects?: number
  numberOfEntities?: number
  alertsCount?: number
  databasesCount?: number
  attributesValidatedCount?: number
  attributesDetectedCount?: number
  createdBy?: string
  lastModifiedTime?: string
  status?: DATASOURCE_STATUS
  registeredOn?: number
  progress?: number
  projectsCount?: number
  ropaProcessesCount?: number
  location?: string
  dataValidationStatus?: DataValidationStatus
  ticketId?: string
  taskId?: string
  taskRequestSentOn?: string
  dueDate?: string
  microsoftEnableAllDomains?: boolean
  docAnalyticsConfiguration?: DocAnalyticsConfiguration
  enabledFeatureFlag?: string[]
  archivalLocation?: string
  isPauseOrResumeActionError?: boolean
  humanReadableSchedulePolicy?: HumanReadableSchedulePolicy
  additionalMetadata?: DsMetadata
}

export type StructuredScanConditionsV2 = {
  type: 'structuredV2'
  filterType: ConnectionStatsTabsV2FilterType
  scanItems: Array<{ database: string }>
}

export type HumanReadableSchedulePolicy = {
  frequencyType: FREQUENCY_TYPES
  frequencyValue: number
  daysOfWeek?: Array<DAY_OF_WEEK>
  daysOfMonth?: number[]
}

export type DsMetadata = {
  flavour?: string
  skippedItems: Array<{ database: string }>
}

export type DataSourceV2 = Omit<DataSource, 'scanConditions'> & {
  humanReadableSchedulePolicy: HumanReadableSchedulePolicy
  scanConditions: StructuredScanConditionsV2[]
}

export type DateIntervalParams = {
  start: string
  end: string
}

export enum DataValidationStatus {
  REQUEST_AWAITING = 'REQUEST_AWAITING'
}

export interface IListItemLabeled {
  info: string
  status: string
}

export interface TimestampRequestParams {
  startDate: number
  endDate: number
}

export interface DateRangeParams {
  startDate: string
  endDate: string
}

export interface NamedTimeSeriesItem {
  value: number
  units: string | null
  timestamp?: number
  name?: string
}
export interface NamedTimeSeries {
  timeseries: Array<NamedTimeSeriesItem>
  name: string
}

export interface SummaryWidgetData {
  [key: string]: NamedTimeSeries
}

/**
 * Instance of a AnnotationsWidget API response.
 */

export interface AnnotationsWidgetData {
  histogram?: Array<NamedTimeSeriesItem>
  totalCount?: number
  classifiedCount?: number
  unclassifiedCountWithEntity?: number
  unclassifiedCountWithoutEntity?: number
  other?: number
}

export interface SuccessFailResponse {
  id?: number
  code?: number
  error?: string
  message: string
}

export interface DataSourceParams {
  offset: number
  limit: number
  sortField?: string
  sortOrder?: number
  searchTerm?: string
}

export interface DataSourceConfiguration {
  datasourceType: string
  name?: string
  salesforceConfiguration?: ConfigSalesforce
  awsS3Configuration?: ConfigAWS
  gsuiteConfiguration?: ConfigGSuite
}

export interface ConfigSalesforce {
  url: string
  clientId: string
  clientSecret: string
  refreshToken: string
}

export interface ConfigAWS {
  secretKey: string
  accessKey: string
}

export interface ConfigGoogleCloudStorage {
  projectId: string
  serviceAccountKey: string
}
export interface ConfigAzureBlob {
  clientId: string
  clientSecret: string
  tenantId: string
}

export interface ConfigElasticSearch {
  hosts: string
  usename?: string
  password?: string
  apiKeyId?: string
  apiKeySecret?: string
}

export interface ConfigMsOffice {
  clientId?: string
  clientSecret?: string
  accountType?: string
  tenantId?: string
  eventHubName?: string
  vaultURL?: string
  secretName?: string
  userMailsToScan?: string[]
  internalDomainNames?: string[]
  docAnalyticsConfiguration?: DocAnalyticsConfiguration
  ignoreInboxMailDomains?: string[]
}
export interface ConfigBox {
  clientId?: string
  clientSecret?: string
  accountType?: string
  enterpriseId?: string
  userMailsToScan?: string[]
}

export interface ConfigDropbox {
  appKey?: string
  refreshToken?: string
  teamAdminEmail?: string
  appSecret?: string
  authType?: DropboxOnboardingMethod
  oauthCode?: string
}

export interface UnstrcuturedDSConfig extends ConfigDropbox, ISharePointFields {}

export interface ElasticSearchIndexFieldResponse {
  datasourceId: string
  index: string
  fields: {
    indexFields: string[]
    currentTimestampField: string
  }
}

export interface ElasticSearchIndexFieldParams {
  datasourceId: string
  index: string
}

export interface ElasticSearchIndexFieldUpdateParams {
  datasourceId: string
  patchRecords: { indexName: string; timestampField: string }[]
}

export enum GSUITE_TYPE {
  org = 'organization',
  personal = 'personal'
}

export enum ONEDRIVE_TYPE {
  org = 'organization',
  personal = 'personal'
}

export interface ConfigGSuite {
  delegatedCredential?: string
  accountJson?: string
  accountType?: GSUITE_TYPE
  folderId?: string
}

export interface ConfigJira {
  email?: string
  server?: string
  apiToken?: string
}

export interface ConfigSlack {
  appToken: string
  botToken: string
  enableLiveSync: boolean
  workspaceUrl?: string
  workspaceName?: string
  workspaceId?: string
  enterpriseId?: string
  autoJoinChannels?: boolean
  filterDate?: string
  enableHistoricalSync?: boolean
  notificationMessageConfiguration?: {
    notifyUser?: boolean
    notifySameChannel?: boolean
    notifySeparateChannelId?: string
  }
}

export interface ConfigSmb {
  smbUrl?: string
  credentials?: {
    username?: string
    password?: string
    domain?: string
  }
  fileShares?: Array<{ shareName?: string; name?: string }>
}

export interface CreateDataSourceResponse {
  id: string
  message: string
}

// Manually Created Interfaces
/**
 * Instance of a datasource connection.
 */
export interface DataSourceConfigurationInstance {
  name?: string
  type?: string
  location?: string
  id?: string
  coordinates?: Coordinates
}

/**
 * This is a generic tag type. It can either be a boundingboxTag or a regexTag. only one of them should be set. (Need to use oneOf in the future).
 */
export interface BoundingBoxTag {
  /**
   * Name of the identifier applied to the bounding box. This value must come from an existing entry (via the _id column) in the identifier collection
   */
  identifierId: string | number
  /**
   * Name of the identifier applied to the bounding box. This value must come from an existing entry (via the name column) in the identifier collection
   */
  identifierName?: string
  value?: string
  /**
   * Unique ID (GUID)
   */
  id?: string
  confidence?: number
  leftUpperX1?: number
  leftUpperY1?: number
  rightLowerX2?: number
  rightLowerY2?: number
  pageNumber?: number
  context?: string
  attributeInstanceId?: string
}

export interface UnlinkedAttribute {
  identifierId: string
  identifierName: string
  line?: number
  line_end?: number
  line_start?: number
  start: number
  value: string
  leftUpperX1?: number
  leftUpperY1?: number
  rightLowerX2?: number
  rightLowerY2?: number
  pageNumber?: number
  context?: string
}

export interface ComplianceViolation {
  id?: string
  /**
   * This enumeration value indicates the compliance type of listing violation.
   */
  complianceType?: string
  /**
   * The unique identifier of the data source ID that currently has the corresponding listing violation{s).
   */
  dataSourceId?: string
  /**
   * The unique identifier of the data source  record ID that currently has the corresponding listing violation{s).
   */
  dataSourceRecordId?: string
  /**
   * The userId identified in the object with the violation{s). This field is only returned if a specific user is identified in the object.
   */
  userId?: string
  /**
   * Note: This field is for future use, and will be set to 100 right now. The unique identifier of the compliance policy.
   */
  policyId?: string
  /**
   * Human readable policy name.
   */
  policyName?: string
}

export interface Coordinates {
  latitude?: number
  longitude?: number
}

/**
 * This is a generic tag type. It can either be a boundingboxTag or a regexTag. only one of them should be set. (Need to use oneOf in the future).
 */
export interface CustomRegexTag {
  /**
   * Name of the identifier applied to the bounding box. This value must come from an existing entry (via the _id column) in the identifier collection
   */
  identifierId?: string
  /**
   * Name of the identifier applied to the bounding box. This value must come from an existing entry (via the name column) in the identifier collection
   */
  identifierName?: string
  value?: string
  /**
   * Unique ID (GUID)
   */
  id?: string
  confidence?: number
  regex?: string
}

/**
 * The schema defining a record within a datasource. An instance of a ddocument can be a record within a table or a file in Google Drive
 */
export interface Document {
  /**
   * ID of the datasource containing the document
   */
  id: string
  /**
   * Name of the document. This should be unique for a datasource and the actual document can be reconstructed based on this value
   */
  name: string
  annotationId?: string
  /**
   * Name of the original document. API sends this only for IM type datasources like Slack.
   */
  imFileName?: string
  /**
   * MD5 checksum of the document. It will be present only for files and not for rows in a table
   */
  checksum?: string
  /**
   * Last modified time of the document. It will be present only for files and not for rows in a table and is represented as a 64-bit integer
   */
  lastModifiedTime?: string | number
  /**
   * Whether this document contains PII data. This value will be false for only files and not for records. For rows, it must be true
   */
  containsPiiData?: boolean
  containsDlpData?: boolean
  /**
   * Type of the document containing the PII record
   */
  type?: DocumentTypeEnum
  entityIds?: Array<string>
  metadata?: { [key: string]: Array<string> }
  customRegexTags?: Array<CustomRegexTag>
  boundingBoxTags?: Array<BoundingBoxTag>
  class?: string
  subclass?: string
  userKeywords?: string[]
  complianceStatus?: Array<{ name: string; status: string }>
  datasourceName?: string
  datasourceType?: string
  datasourceId?: string
  created_at?: string | number
  last_modified_by?: string
  driveId?: string
  driveName?: string
  driveType?: string
  entitiesCount?: number
  riskyEntitiesCount?: number
  sensitiveDataCount?: number
  sharingStatus?: string
  risky?: boolean
  size?: number
  accessibleBy?: Array<string>
  isDuplicate?: boolean
  downloadsCount?: number
  confidence?: number
  isSystemClassified?: boolean
  tags?: Array<string>
  ownerName?: string
  checksumType?: string
  processed?: boolean
  scanned?: boolean
  annotation?: string
  annotationName?: string
  annotationDescription?: string
  /** Param for template update */
  isDraft?: boolean
  objectType?: string
  sharedBy?: {
    name: string
    email: string
  }
  updateTimestamp?: string
  version?: number
  isEntityResolvable?: boolean
  attributeInstanceCount?: number
  datasourceStatsDocumentVersion?: number
  atRisk?: boolean
  policyEvaluationStatus?: string
  policyEventKafkaWriteTimestamp?: string
  activeAlertIds?: string[]
  subAlertIds?: string[]
  blobId?: string
  isTemplate?: boolean // publish doc param for image and pdf
  templateId?: string
  templateName?: string
  labels?: {
    labelId: string
    labelSetId: string
    type: string
  }[]
  unlinkedAttributes?: UnlinkedAttribute[]
}

/**
 * Enum for the document_type property.
 */
export type DocumentTypeEnum =
  | 'txt'
  | 'text'
  | 'pdf'
  | 'png'
  | 'jpg'
  | 'doc'
  | 'docx'
  | 'xls'
  | 'csv'
  | 'row'
  | 'other'
  | 'message'

/**
 * Specific driver license instance
 */
export interface DriverLicense {
  /**
   * Driver License number
   */
  number?: string
  /**
   * Country issuing driver license
   */
  country: string
  /**
   * State or province issuing driver license
   */
  state?: string
}

export interface InlineResponse200 {
  /**
   * Message
   */
  message?: string
}

export interface InlineResponse2001 {
  locationName?: string
  recordsCount?: number
  latlng?: Coordinates
}

export interface InlineResponse201 {
  /**
   * Id
   */
  id?: string
  /**
   * Message
   */
  message?: string
  /**
   * Token
   */
  token?: string
}

export interface InlineResponse2011 {
  /**
   * Id
   */
  id?: string
  /**
   * Message
   */
  message?: string
}

export interface InlineResponse400 {
  message?: string
}

export interface InlineResponse403 {
  /**
   * Error
   */
  error?: string
}

export interface InlineResponse409 {
  /**
   * Error
   */
  error?: string
}

export interface InlineResponse4091 {
  /**
   * Error
   */
  error?: string
}

export interface InlineResponse4092 {
  /**
   * Error
   */
  error?: string
}

export interface InlineResponse4093 {
  /**
   * Error
   */
  error?: string
}

export interface InlineResponse4094 {
  /**
   * Error
   */
  error?: string
}

export interface LoginDetails {
  username: string
  password: string
}

/**
 * PII Data contained for the user in the document
 */
export interface PIIData {
  /**
   * List of all full names corresponding to the user entity
   */
  name?: Array<string>
  /**
   * List of addresses corresponding to the user entity. It should not include the state, country or postal code which are represented below
   */
  address?: Array<string>
  /**
   * List of states or provinces corresponding to the user entity. It must be correlated with the above address entry.
   */
  state?: Array<string>
  /**
   * List of countries corresponding to the user entity. It must be correlated with the above address entry.
   */
  country?: Array<string>
  /**
   * List of postal/zip codes corresponding to the user entity. It must be correlated with the above address entry.
   */
  postalCode?: Array<string>
  /**
   * List of phone numbers corresponding to the user entity
   */
  phoneNumber?: Array<string>
  /**
   * List of emails corresponding to the user entity
   */
  email?: Array<string>
  /**
   * Date of birth in YYYY/MM/DD format for the entity
   */
  dateOfBirth?: string
  /**
   * List of social security numbers corresponding to the user entity
   */
  socialSecurityNumber?: Array<string>
  /**
   * List of passports that the user entity holds
   */
  passport?: Array<Passport>
  /**
   * List of driver licenses that the user entity holds
   */
  driverLicense?: Array<DriverLicense>
}

/**
 * The schema defining an instance of a match between the document and a user entity
 */
export interface PIIRecord {
  /**
   * Unique ID (GUID)
   */
  id?: string
  /**
   * ID of the datasource containing the document
   */
  datasourceId: string
  /**
   * ID of the document in the datasource containing the PII record
   */
  datasourceEntityId?: string
  /**
   * ID of the user contained in the document with a PII record
   */
  userEntityId: string
  /**
   * Location of the datasource containing the document
   */
  datasourceLocation: string
  /**
   * Name of the document containing the PII record
   */
  datasourceEntityName?: string
  /**
   * Type of the document containing the PII record
   */
  datasourceEntityType?: PIIRecordDatasourceEntityTypeEnum
  /**
   * Overall confidence score that we are able to identify the PII data for the user in the document
   */
  confidence?: number
  piiData: PIIData
  documentId?: string
  documentName?: string
  documentType?: string
  datasourceName?: string
}

/**
 * Enum for the datasource_entity_type property.
 */
export type PIIRecordDatasourceEntityTypeEnum =
  | 'txt'
  | 'pdf'
  | 'png'
  | 'jpg'
  | 'doc'
  | 'docx'
  | 'xls'
  | 'csv'
  | 'table'
  | 'other'

/**
 * Specific passport instance
 */
export interface Passport {
  /**
   * Passport number
   */
  number?: string
  /**
   * Country issuing passport
   */
  country: string
}

export interface Signup {
  /**
   * Email
   */
  email: string
  /**
   * Password
   */
  password: string
  /**
   * Name
   */
  name: string
  /**
   * Surname
   */
  surname: string
}

/**
 * The schema defining a unique user entity instance
 */
export interface UserEntity {
  /**
   * Unique ID (GUID).
   */
  id: string
  /**
   * List of all full names corresponding to the user entity
   */
  name: Array<string>
  /**
   * List of addresses corresponding to the user entity. It should not include the state, country or postal code which are represented below
   */
  address?: Array<string>
  /**
   * List of states or provinces corresponding to the user entity. It must be correlated with the above address entry.
   */
  state?: Array<string>
  /**
   * List of countries corresponding to the user entity. It must be correlated with the above address entry.
   */
  country?: Array<string>
  /**
   * List of postal/zip codes corresponding to the user entity. It must be correlated with the above address entry.
   */
  postalCode?: Array<string>
  /**
   * List of phone numbers corresponding to the user entity
   */
  phoneNumber?: Array<string>
  /**
   * List of emails corresponding to the user entity
   */
  email?: Array<string>
  /**
   * Date of birth in YYYY/MM/DD format for the entity
   */
  dateOfBirth?: string
  /**
   * List of social security numbers corresponding to the user entity
   */
  socialSecurityNumber?: Array<string>
  /**
   * List of passports that the user entity holds
   */
  passport?: Array<Passport>
  /**
   * List of driver licenses that the user entity holds
   */
  driverLicense?: Array<DriverLicense>
  documentCount?: number
  sharedInternally?: boolean
  sharedExternally?: boolean
  sharedDocumentCount?: number
  sharedAttributes?: Array<string>
  type?: string
  piiDataCount?: number
  residenciesCount?: number
  risky?: boolean
  residencies?: Array<string>
  datasourceIds?: Array<string>
  driveIds?: Array<string>
  attributesCount?: number
  dataSourcesCount?: number
  violationsCount?: number
  deletedUserEntityReferences: string[]
  lastModifiedTime?: string
  tableCluster?: string
  legalHoldStatus?: {
    hasHoldExpired?: boolean
    holdUntil?: string
    isOnLegalHold?: boolean
  }
}

export interface AuthParams {
  access_token: string
  expires_in: number
  'not-before-policy': number
  refresh_expires_in: number
  refresh_token: string
  scope: string
  session_state: string
  token_type: string
}

/*******************/
/** MOCKED TYPES **/

/** Entity Details */
export interface HistogramData {
  name: string
  value: number
  unit?: string
}

export interface FileTypeWidget {
  name: string
  value: number
}

export interface FileTypeWidgetData {
  totalCount: Array<FileTypeWidget>
  piiCount: Array<FileTypeWidget>
  fileTypesCount: number
}

/** Entities */
export interface EntitiesQueryParams {
  limit?: number
  offset?: number
  searchQuery?: string
  datasourceId?: string
  type?: string
  entityName?: string
  residency?: string
  isAtRisk?: boolean
  isSensitive?: boolean
}

// Global Search
export interface GlobalSearchParams {
  value: string
}

export interface GlobalSearchResults {
  labels: Array<string>
  results: Array<any>
}

// Classification list
export interface Classification {
  id: string
  class: string
  subclasses: Array<string>
  systemDefined: boolean
  enabled?: boolean
  instancesCount?: number
  description?: string
}

export interface WidgetAnnotationsQueryParams {
  startDate?: string
  endDate?: string
  limit?: number
  userEntityId?: string
  datasourceId?: string
}

export interface DocumentApiQueryParams {
  limit?: number
  offset?: number
  annotationId?: string
  userEntityId?: string
  classificationType?:
    | 'classified'
    | 'unclassifiedWithEntity'
    | 'unclassifiedWithoutEntity'
    | 'others'
    | 'all'
    | 'unclassified'
  datasourceId?: string
  driveId?: string
  location?: string
  residency?: string
  entityName?: string
  class?: string
  subclass?: string
  templateName?: string
  containsPIIData?: boolean
  entityType?: string
  type?: string[]
  sharingStatus?: string
  tag?: string
  isAtRisk?: boolean
  isSensitive?: boolean
  isDownloaded?: boolean
  isDuplicate?: boolean
  anonymize?: boolean
  mailId?: string
  messageId?: string
  datasourceType?: string
}

export interface DocumentClassCardsParams {
  userEntityId?: string
  datasourceId?: string
  accessType?: string
  driveId?: string
  class?: string
  subclass?: string
  templateName?: string
  isAtRisk?: boolean
  isSensitive?: boolean
  type?: string[]
  datasourceType?: string
}

export interface SubClass {
  name: string
  documentsCount: number
}

export type DocumentClassCards = {
  accessType?: string
  className?: string
  subclasses?: Array<SubClass>
  documentsCount: number
  riskyDocumentsCount: number
  labelSetName?: string
  labelSetId?: string
  labelId?: string
  labelName?: string
  type?: DOCUMENT_LABEL_PROVIDERS
}

/** Get text labels */
export interface BoundingBoxCoordinates {
  leftUpperX1: number
  leftUpperY1: number
  rightLowerX2: number
  rightLowerY2: number
}

export interface GetTextLabelsParams {
  boundingBox: BoundingBoxCoordinates
  documentId: Document['id']
  pageNumber?: number
}

export interface onDemandDetectedAttributes {
  attribute_name?: string
  value?: string
  attribute_detections: Array<{
    pageNumber: any
    value: string
    bounding_box: {
      leftUpperX1: number
      leftUpperY1: number
      rightLowerX2: number
      rightLowerY2: number
      pageNumber: number
      value: string
      identifierName: string
    }
  }>
}
export interface onDemandResults {
  is_base64?: boolean
  class?: string
  subclass?: string
  detected_attributes: Array<onDemandDetectedAttributes>
  dataId: string
}

export interface onDemandMaskedData {
  identifierId?: string | number
  identifierName: string
  isMasked: boolean
  documentId: string
  value: string
  localId: string
  coordinates: {
    leftUpperX1: number
    leftUpperY1: number
    rightLowerX2: number
    rightLowerY2: number
    pageNumber: number
  }
}

export interface onDemandScannedResults {
  boundingBoxes: Array<{
    identifierId?: string | number
    identifierName: string
    isMasked: boolean
    documentId: string
    value: string
    localId: string
    coordinates: {
      leftUpperX1: number
      leftUpperY1: number
      rightLowerX2: number
      rightLowerY2: number
      pageNumber: number
    }
  }>
}

export interface GetTextLabels {
  value: string
  identifierId?: string
  identifierName?: string
}

export interface GetAnonymizeText {
  data: string
  object_name?: string
  object_type?: string
  is_base64?: boolean
  onlyScan?: boolean
  attributeSets?: string[]
}

export interface ITotalChannelsUsers {
  userCount: number
  privateChannelCount: number
  publicChannelCount: number
  channelCount: number
}

export interface UserEntityBeingShared extends UserEntity {
  datasourceIds?: Array<string>
  documentCount?: number
  sharedInternally?: boolean
  sharedExternally?: boolean
  sharedDocumentCount?: number
  attributesCount?: number
}

export interface IViolations {
  id: string
  complianceType: string
  datasourceId: string
  dataSourceRecordId: string
  userId: string
  policyId: string
  policyName: string
  violationDescription: string
}

export interface DataLocation {
  locationName: string
  coordinates?: Coordinates
  recordsCount: number
}

export interface ConnectedApps {
  totalApps?: number
  totalFiles?: number
  totalFilesSharingData?: number
  totalFilesNotSharingData?: number
}

/** Sensitive info widget */
export interface WidgetSensitiveInfo {
  piiDataEntitiesCount?: NamedTimeSeries
  riskEntitiesCount?: NamedTimeSeries
  piiDataFilesCount?: NamedTimeSeries
  riskFilesCount?: NamedTimeSeries
  residenciesCount?: NamedTimeSeries
  riskResidenciesCount?: NamedTimeSeries
  piiDataMessagesCount?: NamedTimeSeries
  riskMessagesCount?: NamedTimeSeries
  piiMailsCount?: NamedTimeSeries
  riskMailsCount?: NamedTimeSeries
}

export interface WidgetSensitiveInfoQueryParams {
  startDate?: string
  endDate?: string
  driveId?: string
  datasourceId?: string
}

/** ALerts */
export interface AlertInfoItem {
  text: string
  type: string
  dateTime: string
}

/** Anomalies */
export interface AnomaliesInfoItem {
  text: string
  type: string
  dateTime: string
}

export interface SentimentAnalysisItem {
  file: string
  uploadedBy: string
  sentimentLevel: string
}

export interface AuditTrailItem {
  entity: string
  activity: string
  dateTime: string
}

/** GDrive Summary */
export interface GDriveSummary {
  admin?: string
  drivesCount?: number
  usersCount?: number
  groupsCount?: number
  entitiesCount?: number
  filesCount?: number
  location?: string
  driveSize?: number
  driveName?: string
  size?: string
}

export interface GenericSummary {
  projectsCount?: number
  projectOwnersCount?: number
  apiCount?: number
  failureCount?: number
  location?: string
  type?: string
}

export interface JiraSummary {
  ticketsCount?: number
  ticketsWithPIICount?: number
  projectsCount?: number
  location?: string
}

export interface MessagesSummary {
  workspaceName?: string
  membersCount?: number
  channelsCount?: number
  messagesCount?: number
  filesCount?: number
  scannedChannelsCount?: number
  teamsCount?: number
}

export interface OneDriveSummary {
  admin?: string
  drivesCount?: number
  usersCount?: number
  groupsCount?: number
  entitiesCount?: number
  filesCount?: number
  location?: string
  driveSize?: number
  driveName?: string
}

export interface SharePointSummary {
  admin?: string
  drivesCount?: number
  usersCount?: number
  groupsCount?: number
  entitiesCount?: number
  filesCount?: number
  location?: string
  driveSize?: number
  driveName?: string
}
export interface BoxSummary {
  admin?: string
  drivesCount?: number
  usersCount?: number
  groupsCount?: number
  entitiesCount?: number
  filesCount?: number
  location?: string
  driveSize?: number
  driveName?: string
}

export interface AWSS3Summary {
  admin?: string
  bucketSize?: number
  entitiesCount?: number
  filesCount?: number
  location?: string
  size?: number
  bucketOwner?: string
  bucketName?: string
  objectsCount?: number
  bucketsCount?: number
  policiesCount?: number
  driveName?: number
  displayName?: string
  bucketAccess?: 'public' | 'private' | ContainerAccess | string
}

export interface GDriveSummaryQueryParams {
  datasourceId: string
  driveId?: string
}

export interface OneDriveSummaryQueryParams {
  datasourceId: string
  driveId?: string
}

export interface SharePointSummaryQueryParams {
  datasourceId: string
}

export interface AWSS3SummaryQueryParams {
  datasourceId: string
}

export interface BucketSummaryQueryParams {
  datasourceId: string
  bucketId?: string | number
}

/** GMail */
export interface GMailSummaryQueryParams {
  datasourceId: string
}

export interface GMailSummary {
  admin?: string
  size?: number
  emailsCount?: number
  sentEmails?: { total: number; sensitive: number }
  attachmentsCount?: number
  usersCount?: number
  groupsCount?: number
}

export interface GMailUsersWidgetQueryParams {
  datasourceId: string
}

export interface GMailUsersWidget {
  piiCount?: number
  riskCount?: number
  totalCount?: number
}

/** outlook */
export interface OutlookSummaryQueryParams {
  datasourceId: string
}

export interface OutlookSummary {
  admin?: string
  size?: number
  emailsCount?: number
  inboxEmails?: { total: number; sensitive: number }
  sentEmails?: { total: number; sensitive: number }
  attachmentsCount?: number
  usersCount?: number
  groupsCount?: number
}

export interface OutlookUsersWidgetQueryParams {
  datasourceId: string
}

export interface OutlookUsersWidget {
  piiCount?: number
  riskCount?: number
  totalCount?: number
}

/** slack */
export interface SlackSummaryQueryParams {
  datasourceId: string
}

export interface SlackWorkspaceWidget {
  publicChannelPIIDataMessagesCount: number
  publicChannelRiskyMessagesCount: number
  privateChannelPIIDataMessagesCount: number
  privateChannelRiskyMessagesCount: number
  directMessageTotalMessagesCount: number
  riskyDirectMessagesCount: number
}

export interface SlackMembersWidgets {
  piiDataTotalMembersCount: number
  riskyTotalMembersCount: number
  piiDataInternalMembersCount: number
  riskyInternalMembersCount: number
  piiDataGuestMembersCount: number
  riskyGuestMembersCount: number
}

/** Channels */
export interface SlackChannelsWidgets {
  piiDataChannelsCount: number
  riskyChannelsCount: number
}

export interface ImMessage {
  atRisk: boolean
  channelId: string
  channelName: string
  channelType: string
  entitiesCount: number
  messageId: string
  piiAttributes: string[]
  postTime: string
  messageLink: string
  conversationName: string
}
export type ImMessageApiQueryParams = {
  datasourceId?: string
  limit?: number
  offset?: number
  isAtRisk?: boolean
  isSensitive?: boolean
}
export interface SlackWidgetQueryParams {
  datasourceId: string
}
/** Drives */
export interface GDriveWidget {
  personalDrivesCount: number
  piiDataPersonalDrivesCount: number
  riskPersonalDrivesCount: number
  sharedDrivesCount: number
  piiDataSharedDrivesCount: number
  riskSharedDrivesCount: number
}
export interface GDriveWidgetQueryParams {
  datasourceId: string
}

export interface Drive {
  driveId: string
  driveName: string
  driveType: string
  owner: string
  entitiesCount: number
  piiDataCount: number
  piiDataSharedfilesCount: number
  filesSharedCount: number
  risky: boolean
  filesCount: number
  size: number
  status: string
  siteName?: string
  siteUrl?: string
}

export interface DriveApiQueryParams {
  limit?: number
  offset?: number
  datasourceId?: string
  type?: string
  isAtRisk?: boolean
  isSensitive?: boolean
}

/** Users */
export interface User {
  id: string
  name: string
  type: string
  recordCount: number
  classifiedFilesCount: number
  unclassifiedFilesCount: number
  piiCount: number
  riskyCount: number
}

export interface UserApiQueryParams {
  limit?: number
  offset?: number
  datasourceId?: string
  type?: string
}

export interface GDriveUsersWidgets {
  piiCount?: number
  riskCount?: number
  userCount?: number
}
export interface GDriveUsersWidgetsQueryParams {
  datasourceId: string
}

/** Groups */
export interface GDriveGroupsWidgets {
  piiCount?: number
  riskCount?: number
  groupCount?: number
}

export interface GDriveGroupsWidgetsQueryParams {
  datasourceId: string
}

export interface Group {
  id: string
  name: string
  recordCount: number
  piiCount: number
  riskyCount: number
  membersCount: number
}

export interface GroupQueryParams {
  limit?: number
  offset?: number
  datasourceId?: string
  isAtRisk?: boolean
  isSensitive?: boolean
}

/** Residencies */
export interface Residency {
  id: string
  name: string
  location: string
  files: number
  filesAtRisk: number
  entities: number
  lastModifiedTime: string
}

export interface ResidenciesQueryParams {
  offset?: number
  limit?: number
  datasourceId?: string
}

/** Locations */
export interface Location {
  id: string
  name: string
  datasourcesCount: number
  filesCount: number
  entitiesCount: number
}

export interface LocationApiQueryParams {
  offset?: number
  limit?: number
  datasourceId?: string
}

/** Violations */
export interface Violation {
  id: string
  datasourceId: string
  sender: string
  dateTime: string
  receivers: Array<string>
  sensitiveInfoAttributes: Array<string>
  userEntities: Array<{ id: string; name: string }>
}

export interface ViolationApiQueryParams {
  limit?: number
  offset?: number
  datasourceId?: string
  complianceType?: string
  userId?: string
}

/** Templates */
export interface MatchingTemplate {
  id: string
  name: string
  class: string
  confidence: number
  subclass?: string
  createdBy?: string
}

export type CreatedTemplate = {
  templateId: string
  documentId: string
}

export type TemplateFile = {
  id: string
  matchingTemplates: MatchingTemplate[]
}

/** Identifiers */
export interface Identifier {
  id: string
  name: string
  description?: string
  systemDefined?: boolean
  alias?: Array<string>
  sampleData?: Array<string>
  context?: Array<string>
  internalName?: string
}

export type IdentifiersParams = {
  offset?: number
  limit?: number
}

/** Emails */
export type Email = {
  id?: string
  subject?: string
  timestamp?: string
  from: {
    name?: string
    email: string
  }
  recipients: Array<{
    name?: string
    email?: string
  }>
  riskLevel?: 'high' | 'low'
  piiAttributesCount?: number
  riskReason?: number
  entitiesCount?: number
  recipientsCount?: number
  isDeleted?: boolean
  markedForRescan?: boolean
}

export interface EmailApiQueryParams {
  datasourceId?: string
  limit?: number
  offset?: number
  isSensitive?: boolean
  isAtRisk?: boolean
  startDate?: string
  endDate?: string
}
export interface ConversationMessage {
  from: string
  postTime: string
  body: string
}

/** Data source settings */
export interface GSuiteUpsertParams {
  configuration: {
    name: string
    datasourceType: string
    gsuiteConfiguration: ConfigGSuite & {
      name: string
      active: boolean
      scan: {
        unit: string
        value: number
      }
    }
  }
}
export interface SlackUpsertParams {
  configuration: {
    name: string
    datasourceType: string
    slackConfiguration: {
      active: boolean
      appToken: string
      botToken: string
      name: string
      scan: {
        unit: string
        value: number
      }
    }
  }
}

/** User feedback */
export enum WrongAnnotationItems {
  classification = 'Classification',
  subClassification = 'Sub-classification',
  entityIncorrect = 'Incorrect Entity',
  entityMissing = 'Missing Entity',
  identifierIncorrect = 'Incorrect Identifier value',
  identiferMissing = 'Missing Identifier'
}
export interface AnnotationFeedbackParams {
  wrongAnnotationItems: Array<WrongAnnotationItems>
  remarks: string
}

export type ConnectionStats = Array<{
  database: string
  schemaCount?: number
  tableCount?: number
  schemaName?: string
  columnCount?: number
}>

export interface GlueConnectionStats {
  database: string[]
  workgroup: string[]
}

export interface LBVersionDetails {
  version: string
  buildNumber: number
  releaseDate: string
  updateDate: string
}
export interface CosmosPostgresConnectionStats {
  [key: string]: string[]
}

export type TestConnectionDetails = {
  username: string
  port: number
  host: string
  authenticationMechanism: string
  awsRegion: string
}

type AuditLogData = {
  '@timestamp': string
  module: string
  subModule?: string
  action: string
  endpoint: string
  status: string
  code: number
  uid: string | null
  email: string | null
}

export type AuditLog = {
  id: string
  data: AuditLogData
}

export type AuditLogsResponse = {
  total_pages: number
  page: number
  size: number
  logs: AuditLog[]
}

type AuditLogsMappingKeys = 'email' | 'module' | 'action' | 'status'
export type AuditLogsMapping = Record<AuditLogsMappingKeys, string[]>
