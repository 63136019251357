import ChartTooltip from '../ChartTooltip'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { PieChart, Pie, Cell, Label, Tooltip } from 'recharts'
import React, { SVGProps } from 'react'

const LABEL_COUNT_SHIFT = 10
const CustomLabel = (props): React.ReactElement => {
  const {
    viewBox,
    total,
    text,
    centerCountStyles,
    centerTextStyles,
    link,
    showHalfCircle,
    clickable = true
  } = props
  const { cx, cy } = viewBox
  const intl = useIntl()

  const Label = () => (
    <>
      <text
        x={cx}
        y={text || showHalfCircle ? cy - LABEL_COUNT_SHIFT : cy}
        fill="#3d405c"
        {...(centerCountStyles ? centerCountStyles : {})}
        className="recharts-label text"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan
          alignmentBaseline="middle"
          fontSize="14"
          fontWeight="500"
          {...(centerCountStyles ? { style: centerCountStyles } : {})}
          data-test-id="chart-pie-central-label-value"
          className="central-value"
        >
          {intl.formatNumber(total, { notation: 'compact' })}
        </tspan>
      </text>
      {text && (
        <text
          x={cx}
          y={centerTextStyles?.y || cy + 15}
          fill="#3d405c"
          className="recharts-label text"
          textAnchor="middle"
          dominantBaseline="central"
          {...(centerTextStyles ? { style: centerTextStyles } : {})}
        >
          <tspan
            fontSize="10"
            {...(centerTextStyles ? { style: centerTextStyles } : {})}
            data-test-id="chart-pie-central-label-text"
          >
            {text}
          </tspan>
        </text>
      )}
    </>
  )

  return clickable ? (
    <Link to={link || '#'}>
      <Label />
    </Link>
  ) : (
    <Label />
  )
}

export type ChartPieItem = {
  key: string
  value: number
  color: string
  link?: string
  percentage?: number
  onClick?: (key?: string) => void
}

interface IProps {
  data: ChartPieItem[]
  text?: string | number
  centerCountStyles?: React.CSSProperties & SVGProps<SVGAElement>
  centerTextStyles?: React.CSSProperties & SVGProps<SVGAElement>
  width?: number
  height?: number
  link?: Array<{ key: string; link: string }> | string
  onClick?: (val: string) => void
  showHalfCircle?: boolean
  innerRadius?: number
  outerRadius?: number
  gap?: number
  className?: string
  total?: number
  totalLink?: string
  newWindow?: boolean
  isAnimated?: boolean
  isClickable?: boolean
  customToolTip?: React.ReactElement
}

const ChartPie = (props: IProps): React.ReactElement => {
  const {
    data,
    text,
    width = 150,
    height,
    centerCountStyles,
    centerTextStyles,
    showHalfCircle = false,
    innerRadius,
    outerRadius,
    gap = 0,
    className,
    link,
    total = 0,
    onClick,
    totalLink,
    newWindow = false,
    isAnimated = true,
    isClickable = true,
    customToolTip
  } = props

  const labelTotal =
    total || data.map((item) => item.value).reduce((acc, current) => acc + current, 0)

  const heightCalc = height || width
  const outerRadiusCalc = outerRadius || heightCalc / 2
  const innerRadiusCalc = innerRadius || outerRadiusCalc - 20

  if (isNaN(heightCalc) || isNaN(outerRadiusCalc) || isNaN(innerRadiusCalc)) {
    return <></>
  }

  return (
    <div className={`chart-pie-wrapper ${className}`} data-test-id="chart-pie">
      <PieChart
        className="chart-pie"
        width={width}
        height={showHalfCircle ? heightCalc / 2 : heightCalc}
      >
        <Pie
          data={data}
          startAngle={showHalfCircle ? 0 : 90}
          endAngle={showHalfCircle ? 180 : 450}
          minAngle={2}
          innerRadius={innerRadiusCalc}
          outerRadius={outerRadiusCalc}
          paddingAngle={gap}
          dataKey="value"
          isAnimationActive={isAnimated}
          cy={showHalfCircle ? '112%' : '50%'}
          cx="50%"
          onClick={(params) => {
            if (link) {
              let filterLink = ''
              if (typeof link === 'string') {
                filterLink = link + params.key
              } else {
                filterLink = Object.values(link).filter((obj) => obj.key === params.key)[0].link
              }

              newWindow ? window.open(filterLink, '_blank') : (window.location.href = filterLink)
            }
            onClick && onClick(params.key)
          }}
        >
          {data.map((item, i) => (
            <Cell
              onClick={() => item?.onClick?.(item.key)}
              key={`cell-${i}`}
              fill={item.color}
              cursor={onClick ? 'pointer' : ''}
            />
          ))}
          <Label
            width={30}
            position="center"
            content={
              <CustomLabel
                total={labelTotal}
                text={text}
                centerCountStyles={centerCountStyles}
                centerTextStyles={centerTextStyles}
                link={totalLink}
                showHalfCircle={showHalfCircle}
                clickable={isClickable}
              />
            }
          />
        </Pie>
        <Tooltip content={customToolTip ? customToolTip : <ChartTooltip />} />
      </PieChart>
    </div>
  )
}

export default ChartPie
