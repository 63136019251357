import './notification.less'
import NotificationItem from './components/notificationItem'
import { removeNotification, INotification, clearNotification } from './notificationSlice'
import { RootState } from '../../rootReducer'
import useUrlContext from '../../hooks/useUrlContext'
import { URL_LOGIN } from '../../constants'
import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Message } from 'semantic-ui-react'

const MAX_NOTIFICATIONS_COUNT = 3

interface IProps {
  notificationsList: INotification[]
  removeNotification: (id: string) => void
  clearNotification: () => void
}

const Notification = (props: IProps): React.ReactElement => {
  const { notificationsList, removeNotification, clearNotification } = props

  const context = useUrlContext()
  const [currentPath, setCurrentPath] = useState('')
  const [timers, setTimers] = useState<{ timerId: number; id: string }[]>([])
  const idsWithTimer = useMemo(() => timers.map((timerObj) => timerObj.id), [timers])

  useEffect(() => {
    if (currentPath !== context.pathname && context.pathname !== URL_LOGIN) {
      setCurrentPath(context.pathname)
      clearNotification()
    }
  }, [context.pathname, currentPath, timers, removeNotification, clearNotification])

  useEffect(() => {
    if (notificationsList.length > MAX_NOTIFICATIONS_COUNT) {
      removeNotification(notificationsList[0].id)
    }

    notificationsList.forEach(({ id, autoHideAfterSec = 1 }) => {
      if (!idsWithTimer.includes(id)) {
        setTimers((prev) => {
          const timerId = window.setTimeout(() => removeNotification(id), autoHideAfterSec * 1000)

          return [...prev, { id, timerId }]
        })
      }
    })
  }, [idsWithTimer, notificationsList, removeNotification])

  if (notificationsList.length === 0) return <></>

  const notificationsToShow =
    notificationsList.length >= MAX_NOTIFICATIONS_COUNT
      ? notificationsList.slice(notificationsList.length - MAX_NOTIFICATIONS_COUNT)
      : notificationsList

  return (
    <Message className="notification-container" data-test-id="notification-notification">
      {notificationsToShow.map(({ id, success, error, values }) => (
        <NotificationItem
          key={id}
          id={id}
          success={success}
          error={error}
          values={values}
          onClose={removeNotification}
        />
      ))}
    </Message>
  )
}

const mapStateToProps = (state: RootState) => ({
  notificationsList: state.notification.list
})

const mapDispatchToProps = {
  removeNotification,
  clearNotification
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
