import apiService from './api/apiService'
import { accessTokenKey } from '../constants'
import { logout } from '../features/login/loginSlice'
import { store } from '../index'
import { GraphQLClient } from 'graphql-request'
import 'abort-controller/polyfill'
import { parse } from 'query-string'

const abortControllers: { [methodName: string]: AbortController } = {}

export type GraphQlError = {
  data: any
  errors: Array<{ message: string }>
  status: number
}

const graphqlService = {
  async execute(query: string, methodName?: string): Promise<any | GraphQlError> {
    if (methodName) {
      abortControllers[methodName] = new AbortController()
    }
    const { requestId: pdfRequestId } = parse(window.location.search) as {
      requestId: string
    }

    const graphQLClient = new GraphQLClient('/api/graphql', {
      headers: {
        [accessTokenKey]: window.localStorage.getItem(accessTokenKey) || '',
        'oidc-redirect': 'false'
      }
    })
    try {
      const response = await graphQLClient.request({
        document: query,
        ...(methodName ? { signal: abortControllers[methodName].signal as any } : {}) // types is not presented yet
      })
      return response
    } catch (error: any) {
      if (error?.response && error.response.status === 401) {
        store.dispatch(logout())
      }

      const errorData = JSON.parse(JSON.stringify(error))
      if (pdfRequestId)
        await apiService.postPdfDownloadError(
          pdfRequestId,
          {
            url: '/api/graphql',
            body: { query },
            statusCode: errorData.status,
            response: errorData.errors,
            method: 'POST'
          },
          'failed'
        )

      throw errorData.response
    }
  },

  abort(methodName: string) {
    abortControllers[methodName]?.abort()
  }
}

export default graphqlService
