import {
  DsrTicketListParams,
  DsrTicket,
  DsrTicketDetailParams,
  SaveDsrTicketParams,
  AttributeInstancesValidatedSummary,
  AssignTicketParams
} from './dsrTicketsSlice'
import { LIMIT_DEFAULT } from '../../../constants'
import {
  getAfterCursor,
  parameterizeArrayofObjects,
  stringifyParams
} from '../../../utils/graphqlUtil'
import { groupByProperty } from '../../../utils/common'
import { FilterParams } from '../../../interfaces'
import { escapeNewlineandTab } from '../../../utils/stringUtil'
import { DsrRequestType } from '../../../services/graphqlSchemaTypes'
import { NameIdSummary } from '../../pia/piaSlice'
import { gql } from 'graphql-request'

export const queryDSRTicketsList = (params: DsrTicketListParams): string => {
  const { page, pageSize = LIMIT_DEFAULT, ...listFilters } = params
  const cursor = getAfterCursor(page, pageSize)
  const filterString = parameterizeArrayofObjects(listFilters.filters?.filter || [])
  const dateFilterString = stringifyParams(listFilters.filters?.dateFilter || {})
  let commonParamString = ''
  if (filterString) {
    commonParamString += `,filter:${filterString}`
  }
  if (dateFilterString) {
    commonParamString += `,dateFilter:{${dateFilterString}}`
  }

  return gql`
    {
      dsrTicket(sortField: CREATED_AT, first: ${pageSize}, after: "${cursor}", ${commonParamString}) {
        edges {
            node {
              id
              name
              status
              completionDate
              createdAt
              dueDate
              assigneeEmail
              datasource {
                edges {
                  node {
                    name
                    type
                  }
                }
              }
              dsrRequest {
                edges {
                  node {
                    id
                    name
                    requestType
                    dataSubjectMandatoryDetails {
                      lastName
                      firstName
                      email
                    }
                  }
                }
              }
            }
          }
      }
    }
  `
}

export const mapQueryDSRTicketsList = (raw: any): DsrTicket[] => {
  const list: DsrTicket[] = raw.dsrTicket.edges.map(({ node: ticket }) => {
    const request = ticket?.dsrRequest?.edges[0]?.node

    return {
      ...ticket,
      datasource: ticket.datasource?.edges[0]?.node || {},
      request: {
        id: request?.id,
        name: request?.name,
        requestType: request?.requestType,
        dataSubjectMandatoryDetails: {
          firstName: request?.dataSubjectMandatoryDetails?.firstName,
          lastName: request?.dataSubjectMandatoryDetails?.lastName,
          email: request?.dataSubjectMandatoryDetails?.email
        }
      }
    }
  })

  return list
}

export const queryTicketInstancesValidatedCounts = (requestId: string): string => {
  return gql`
    {
      dsrReport(filter: [{key: REQUEST_ID,values: ["${requestId}"]}]) {
        edges {
          node {
            validatedAttributes {
              attributeTypeName
              validatedAttributeValues {
                attributeValidatedValue
                dsrTicket {
                  edges {
                    node {
                      id
                      datasource {
                        edges {
                          node {
                            name
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryTicketInstancesValidatedCounts = (
  raw: any
): AttributeInstancesValidatedSummary[] => {
  const validatedCounts: AttributeInstancesValidatedSummary[] = []
  const report = raw.dsrReport?.edges[0]?.node || {}
  report.validatedAttributes?.map((validatedAttribute) => {
    validatedAttribute.validatedAttributeValues?.forEach((validatedAttributeValue) => {
      const ticket = validatedAttributeValue.dsrTicket?.edges[0]?.node || {}
      const datasource = ticket.datasource?.edges[0]?.node || {}
      validatedCounts.push({
        datasourceId: datasource.id || '',
        count: validatedAttribute.validatedAttributeValues?.length || 0
      })
    })
  })
  const datasourceCounts = groupByProperty(validatedCounts, 'datasourceId')
  const datasourceCountsArray = Object.keys(datasourceCounts).map((datasourceId) => ({
    count: datasourceCounts[datasourceId]?.length || 0,
    datasourceId
  }))

  return datasourceCountsArray
}

export const queryDsrTicketsSummary = (filters: FilterParams): string => {
  const filterString = parameterizeArrayofObjects(filters?.filter || [])
  return gql`
    {
      dsrTicket(filter:${filterString}) {
        edges {
          node {
            id
            name
            status
            dueDate
            assigneeEmail
            datasource {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryDsrTicketsSummary = (raw: any): DsrTicket[] => {
  const list = raw.dsrTicket.edges.map(({ node: ticket }) => ({
    ...ticket,
    datasourceId: ticket.datasource?.edges[0]?.node?.id || ''
  }))
  return list
}

export const queryDsrTicketsSummaryCounts = (params: DsrTicketListParams): string => {
  const { ...listFilters } = params

  const filtersCompleted = parameterizeArrayofObjects([
    ...(listFilters.filters?.filter?.filter((item) => item.key !== 'STATUS') || []),
    { key: 'STATUS', values: ['rejected', 'completed'] }
  ])

  const filtersInProgress = parameterizeArrayofObjects([
    ...(listFilters.filters?.filter?.filter((item) => item.key !== 'STATUS') || []),
    { key: 'STATUS', values: ['ticketAssigned', 'inProgress'] }
  ])

  const filtersAll = parameterizeArrayofObjects(
    listFilters.filters?.filter?.filter((item) => item.key !== 'STATUS') || []
  )

  const dateFilterString = stringifyParams(listFilters.filters?.dateFilter || {})
  let commonParamString = ''

  if (dateFilterString) {
    commonParamString += `,dateFilter:{${dateFilterString}}`
  }

  // Construct the allFilterString conditionally
  let allFilterString = ''
  if (filtersAll && commonParamString) {
    allFilterString = `(filter: ${filtersAll}${commonParamString})`
  } else if (filtersAll) {
    allFilterString = `(filter: ${filtersAll})`
  } else if (commonParamString) {
    allFilterString = `(${commonParamString.slice(1)})` // Remove leading comma for commonParamString
  }

  return gql`
    {
      totalTicketsCompleted: dsrTicket(filter:${filtersCompleted}${commonParamString}) {
        count
      }
      totalTicketsInProgress: dsrTicket(filter:${filtersInProgress}${commonParamString}) {
        count
      }
      totalTickets: dsrTicket${allFilterString} {
        count
      }
    }
  `
}

export const mapQueryDsrTicketsSummaryCounts = (
  raw: any
): {
  totalTickets: number
  totalTicketsInProgress: number
  totalTicketsCompleted: number
} => {
  return {
    totalTicketsCompleted: raw.totalTicketsCompleted.count || 0,
    totalTicketsInProgress: raw.totalTicketsInProgress.count || 0,
    totalTickets: raw.totalTickets.count || 0
  }
}

export const queryDsrTicketDetails = ({ ticketId, requestType }: DsrTicketDetailParams): string => {
  const actionsKeyMapper = {
    [DsrRequestType.Access]: 'DSRAttributeAccessActions',
    [DsrRequestType.Rectification]: 'DSRAttributeRectificationActions',
    [DsrRequestType.Erasure]: 'DSRAttributeErasureActions',
    [DsrRequestType.DoNotSell]: 'DSRAttributeAccessActions',
    [DsrRequestType.Other]: 'DSRAttributeAccessActions'
  }
  const isAccess = requestType == DsrRequestType.Access
  const isDoNotSell = requestType == DsrRequestType.DoNotSell
  const isOther = requestType == DsrRequestType.Other

  return gql`
    {
      dsrTicket(id: "${ticketId}") {
        edges {
          node {
            id
            name
            dueDate
            status
            isDraft
            createdAt
            completionDate
            remark

            assigneeEmail
            purpose{
              edges{
                node{
                  id
                  fieldType
                  value
                }
              }
            }
            datasource {
              edges {
                node {
                  id
                  name
                  type
                  createdBy
                }
              }
            }
            attachments {
              edges {
                node {
                  id
                  fileName
                }
              }
            }
            dsrRequest {
              edges {
                node {
                  id
                  name
                  createdAt
                  approvedBy
                  approvedAt
                  updateTimestamp
                  dueDate
                  status
                  requestType
                  erasureData
                  submittedValues
                  language
                  form {
                    edges {
                      node {
                        id
                        name
                        formRawJSON
                      }
                    }
                  }
                  rectificationData {
                    attribute {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                    oldValue
                    newValue
                  }
                  dataSubjectMandatoryDetails {
                    firstName
                    lastName
                    email
                  }
                  assignee {
                    email
                    name
                  }
                  detectedEntity {
                    edges {
                      node {
                        id
                        name
                        datasources {
                          edges {
                            node {
                              id
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            actions {
              ... on ${actionsKeyMapper[requestType]} {
                action
                remark
                origin
                manualValue
                ${isAccess || isOther || isDoNotSell ? 'dataMismatch' : 'reason'}
                attributeInstanceId
                attribute {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryDsrTicketDetails = (raw: any): DsrTicket => {
  try {
    const ticket = raw.dsrTicket?.edges[0]?.node || {}
    const request = ticket.dsrRequest?.edges[0]?.node || {}
    const result = {
      id: ticket.id || '',
      name: ticket.name || '',
      dueDate: ticket.dueDate || '',
      status: ticket.status || '',
      isDraft: ticket.isDraft,
      createdAt: ticket.createdAt || '',
      assigneeEmail: ticket.assigneeEmail || '',
      completionDate: ticket.completionDate || '',
      actions:
        ticket.actions?.map((action) => ({
          attributeId: action.attribute?.edges[0]?.node?.id,
          attributeName: action.attribute?.edges[0]?.node?.name,
          attributeInstanceId: action.attributeInstanceId || '',
          action: action.action || '',
          origin: action.origin || '',
          manualValue: action.manualValue || '',
          remark: action.remark || '',
          reason: action.reason || [],
          dataMismatch: action.dataMismatch || false
        })) || [],
      purpose: ticket.purpose.edges.map(({ node }) => node.id) || [],
      attachments:
        ticket.attachments?.edges?.map(({ node }) => ({
          id: node.id,
          name: node.fileName
        })) || [],
      remark: ticket.remark || '',
      datasource: {
        id: ticket.datasource?.edges[0]?.node?.id || '',
        name: ticket.datasource?.edges[0]?.node?.name || '',
        type: ticket.datasource?.edges[0]?.node?.type || '',
        createdBy: ticket.datasource?.edges[0]?.node?.createdBy || ''
      },
      request: {
        id: request.id || '',
        name: request.name || '',
        createdAt: request.createdAt || '',
        approvedBy: request.approvedBy || '',
        approvedAt: request.approvedAt || '',
        updateTimestamp: request.updateTimestamp || '',
        dueDate: request.dueDate || '',
        status: request.status || '',
        requestType: request.requestType || '',
        formId: request?.form?.edges[0]?.node?.id || '',
        formName: request.form?.edges[0]?.node?.name || '',
        formRawJSON: request.form?.edges[0]?.node.formRawJSON || '',
        formJsonParsed: request.form?.edges[0]?.node.formRawJSON
          ? JSON.parse(request.form?.edges[0]?.node.formRawJSON)
          : {},
        submittedValues: request?.submittedValues || '',
        rectificationData:
          request.rectificationData?.map(({ attribute, oldValue, newValue }) => ({
            newValue,
            oldValue,
            attributeId: attribute.edges[0]?.node?.id || '',
            attributeName: attribute.edges[0]?.node?.name || ''
          })) || [],
        erasureData: request.erasureData || '',
        detectedEntity: {
          id: request.detectedEntity?.edges[0]?.node.id || '',
          name: request.detectedEntity?.edges[0]?.node.name || '',
          datasourceIds:
            request.detectedEntity?.edges[0]?.node?.datasources?.edges?.map(
              ({ node: ds }) => ds.id || ''
            ) || []
        },
        dataSubjectMandatoryDetails: {
          firstName: request.dataSubjectMandatoryDetails?.firstName || '',
          lastName: request.dataSubjectMandatoryDetails?.lastName || '',
          email: request.dataSubjectMandatoryDetails?.email || ''
        },
        assignee: request.assignee || '',
        choosenFormlanguage: request?.language || ''
      }
    }
    return result
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const assignDsrRequestMapper = {
  [DsrRequestType.Rectification]: 'assignDsrRectificationTicket',
  [DsrRequestType.Access]: 'assignDsrAccessTicket',
  [DsrRequestType.Erasure]: 'assignDsrErasureTicket',
  [DsrRequestType.Other]: 'assignDsrAccessTicket',
  [DsrRequestType.DoNotSell]: 'assignDsrAccessTicket'
}
export const queryAssignDsrTicket = ({
  assigneeEmail,
  datasourceIds,
  requestId,
  requestType,
  dueDate,
  text
}: AssignTicketParams) => {
  const datasourceIdsArr = datasourceIds.map((id) => '"' + id + '"')

  return gql`
    mutation {
      ${assignDsrRequestMapper[requestType]}(
        clientMutationId: "assignDsrTicket"
        datasourceIds: [${datasourceIdsArr.join(',')}]
        assigneeEmail: "${assigneeEmail || ''}"
        requestId: "${requestId}"
        ticketData: { isDraft: true, emailBody: "${escapeNewlineandTab(
          text
        )}", dueDate: "${dueDate}" }
      ) {
        clientMutationId
        ticket
      }
    }
  `
}

export const queryMutationUpdateDsrTicket = ({
  ticketData,
  ticketId,
  attachmentIds,
  requestType
}: SaveDsrTicketParams & { attachmentIds: string[] }): any => {
  const isAccess = requestType === DsrRequestType.Access
  const isOther = requestType === DsrRequestType.Other
  const isDoNotSell = requestType === DsrRequestType.DoNotSell
  const isRectification = requestType === DsrRequestType.Rectification
  const muationTypeMapper = {
    [DsrRequestType.Access]: 'updateDsrAccessTicket',
    [DsrRequestType.Other]: 'updateDsrAccessTicket',
    [DsrRequestType.DoNotSell]: 'updateDsrAccessTicket',
    [DsrRequestType.Rectification]: 'updateDsrRectificationTicket',
    [DsrRequestType.Erasure]: 'updateDsrErasureTicket'
  }
  const getMismatchString = (action) =>
    isAccess || isOther || isDoNotSell ? `dataMismatch: ${action.dataMismatch}` : ''

  try {
    const actionsFragment = ticketData.actions
      .map((ticketAction) => {
        const {
          reason = [],
          attributeId,
          action,
          origin,
          remark,
          attributeInstanceId,
          previousValue,
          manualValue
        } = ticketAction
        const reasonsArr = reason.map((reasonValue) => '"' + reasonValue + '"') || []
        return `{
          attributeId: "${attributeId}",\
          action: ${action}
          origin: ${origin || 'system'}
          remark: "${remark}",\
          ${getMismatchString(ticketAction)}
          ${attributeInstanceId ? `attributeInstanceId: "${attributeInstanceId}"` : ''}
          ${previousValue?.trim() ? `previousValue: "${previousValue}"` : ''}
          ${
            origin == 'manual'
              ? `manualValue: "${manualValue?.replace(/(\r\n|\n|\r)/gm, '') || ''}"`
              : ''
          }
          ${isRectification && !previousValue?.trim() ? `previousValue: ""` : ''}
          ${reason && reason.length > 0 ? `reason: [${reasonsArr.join(',')}]` : ''}
        }`
      })
      .join()
    const attachments = attachmentIds.map((id) => '"' + id + '"')

    return gql`
      mutation {
        ${muationTypeMapper[requestType]}(
          clientMutationId: "1",
          ticketId: "${ticketId}",
          attachmentIds: [${attachments.join(',')}]
          ticketData: {
            dueDate: "${ticketData.dueDate}"
            isDraft: ${ticketData.isDraft}
            actions: [${actionsFragment}]
            purpose: ${JSON.stringify(ticketData.purpose || [])}
            remark: "${ticketData.remark}"
          }
        ) {
          clientMutationId
        }
      }
    `
  } catch (error) {
    console.error(error)
    throw error
  }
}

/* Mutation for Send Reminder Action */
export const mutationSendReminder = (params) => {
  const { ticketId, dueDate, emailBody } = params
  return gql`
    mutation {
      sendDsrTicketReminder(
        clientMutationId: "test"
        ticketId: "${ticketId}"
        ticketData: { dueDate: "${dueDate}", emailBody: "${encodeURIComponent(emailBody)}" }
      ) {
        clientMutationId
        ticket
      }
    }
  `
}

/* Mutation for Reassign Ticket Action */
export const mutationReassignTicket = (params) => {
  const { ticketId, dueDate, assigneeEmail, emailBody } = params
  return gql`
    mutation {
      reassignDsrTicket(
        clientMutationId: "test"
        ticketId: "${ticketId}"
        ticketData: { dueDate: "${dueDate}", emailId: "${assigneeEmail}", emailBody: "${encodeURIComponent(
    emailBody
  )}" }
      ) {
        clientMutationId
        ticket
      }
    }
  `
}

/* Mutation for Send New Ticket Action */
export const mutationSendNewTicket = (params) => {
  const { requestId, dataSourceId, dueDate, assigneeEmail, emailBody } = params
  return gql`
    mutation {
      sendNewDsrTicket(
        clientMutationId: "test"
        emailId: "${assigneeEmail}"
        requestId: "${requestId}"
        ticketData: { dueDate: "${dueDate}", emailBody: "${encodeURIComponent(
    emailBody
  )}", datasourceIds: ["${dataSourceId}"] }
      ) {
        clientMutationId
        ticket
      }
    }
  `
}

/* Mutation for Send New Ticket Action */
export const mutationRevokeAssignment = (params) => {
  const { ticketId } = params
  return gql`
    mutation {
      revokeDsrTicket(clientMutationId: "test", ticketId: "${ticketId}") {
        clientMutationId
        isRevoked
      }
    }
  `
}

/* Mutation for Reject Ticket Action */
export const mutationRejectTicket = (params) => {
  const { ticketId, emailBody } = params
  return gql`
    mutation {
      rejectDsrTicket(
        clientMutationId: "test"
        ticketData: { emailBody: "${encodeURIComponent(emailBody)}" }
        ticketId: "${ticketId}"
      ) {
        clientMutationId
        ticket
      }
    }
  `
}

export const queryDSRTicketDatasources = (): string => {
  return gql`
    {
      dataSubjectTicketAssociatedDatasources {
        edges {
          node {
            id
            name
            type
          }
        }
      }
    }
  `
}

export const mapQueryDSRTicketDatasources = (raw: any): NameIdSummary[] => {
  return (
    raw?.dataSubjectTicketAssociatedDatasources?.edges?.map(({ node: ds }) => ({
      ...ds,
      id: ds?.id,
      name: ds?.name,
      type: ds?.type
    })) || []
  )
}
