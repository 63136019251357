import {
  FetchPartnerObjectsSharedListParams,
  GetPartnersCompactListParams,
  IGetListParams,
  ObjectShared,
  PartnerCardList,
  PartnerInfo,
  PartnerList,
  PartnerListCompact,
  ExternalMembersList,
  ExternalMembersListCompact,
  PartnerSumary
} from './partnersSlice'
import {
  EXTERNAL_EMAIL_GROUP_TEXT,
  GENERIC_EMAIL_PARTNERS,
  LIMIT_DEFAULT,
  mapDataSourceApiTypesToStandard
} from '../../constants'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { FilterParams } from '../../interfaces'
import { IGetAttributesFilterParams } from '../attributes/attributesSlice'
import gqlast from '../../utils/filtersUtil'
import { gql } from 'graphql-request'

export const getAggregateGeneralPartners = (partners) => {
  return partners.reduce(
    (acc, item) => {
      return {
        id: GENERIC_EMAIL_PARTNERS,
        name: EXTERNAL_EMAIL_GROUP_TEXT,
        objectsCount: acc.objectsCount + (item?.objectsCount || 0),
        attributeCount: acc.attributeCount + (item?.attributeCount || 0),
        attributeInstanceCount: acc.attributeInstanceCount + (item?.attributeInstanceCount || 0),
        entitiesCount: acc.entitiesCount + (item?.entitiesCount || 0),
        datasourceCount: acc.datasourceCount + (item?.datasourceCount || 0),
        piiObjectCount: acc.piiObjectCount + (item?.piiObjectCount || 0),
        alertsCount: acc.alertsCount + (item?.alertsCount || 0),
        objectsImpactedCount: acc.objectsImpactedCount + (item?.objectsImpactedCount || 0),
        ids: [...acc.ids, item.id]
      }
    },
    {
      id: GENERIC_EMAIL_PARTNERS,
      name: EXTERNAL_EMAIL_GROUP_TEXT,
      objectsCount: 0,
      attributeCount: 0,
      attributeInstanceCount: 0,
      entitiesCount: 0,
      datasourceCount: 0,
      piiObjectCount: 0,
      alertsCount: 0,
      objectsImpactedCount: 0,
      ids: []
    }
  )
}

export const queryPartnersListCompact = ({
  page,
  filter
}: GetPartnersCompactListParams & FilterParams): string => {
  const cursor = page ? getAfterCursor(page, LIMIT_DEFAULT) : ''

  let filterString = ''

  if (filter?.length) {
    filterString = `,filter:${parameterizeArrayofObjects(filter)}`
  }

  return gql`
  {
    partner(booleanFilter: {key: IS_GENERAL_PARTNER, value: false}, first: ${LIMIT_DEFAULT}, after: "${cursor}", sortField:ATTRIBUTE_INSTANCE_COUNT ${filterString}) {
      count
      edges {
        node {
          id
          name
          isGeneralPartner
          objects {
            count
          }
        }
      }
    }
  }
`
}

// TODO: add type for graphql response
export const mapQueryPartnersListCompact = (raw: any): PartnerListCompact => {
  try {
    if (raw.partner?.edges && raw.partner?.edges.length) {
      const list = raw.partner?.edges.map(({ node }) => {
        return {
          id: node.id,
          name: node.name,
          isGeneralPartner: node.isGeneralPartner,
          labels:
            node.labels?.edges?.map(({ node: label }) => ({ id: label.id, name: label.name })) ||
            [],
          objectsCount: node.objects?.count || 0
        }
      })

      return { listCompact: list, total: raw?.partner?.count || 0 }
    }
    return { listCompact: [], total: 0 }
  } catch (e) {
    console.error(e)
    return { total: 0 }
  }
}
export const queryExternalMembersListCompact = ({
  filter
}: GetPartnersCompactListParams & FilterParams): string => {
  let filterString = ''

  if (filter?.length) {
    filterString = `,filter:${parameterizeArrayofObjects(filter)}`
  }

  return gql`
    {
      partner(booleanFilter: { key: IS_GENERAL_PARTNER, value: true } ${filterString}) {
        count
        edges {
          node {
            id
            name
            isGeneralPartner
            objects {
              count
            }
          }
        }
      }
    }
  `
}
export const mapQueryExternalMembersListCompact = (raw: any): ExternalMembersListCompact => {
  try {
    if (raw.partner?.edges && raw.partner?.edges.length) {
      const list = raw.partner?.edges.map(({ node }) => {
        return {
          id: node.id,
          name: node.name,
          isGeneralPartner: node.isGeneralPartner,
          objectsCount: node.objects?.count || 0
        }
      })
      const externalMembersList = [getAggregateGeneralPartners(list)]
      return { membersListCompact: externalMembersList }
    }
    return { membersListCompact: [] }
  } catch (e) {
    console.error(e)
    return { membersListCompact: [] }
  }
}

export const queryPartnersCard = ({
  page = 1,
  pageSize = LIMIT_DEFAULT,
  filter,
  booleanFilter
}: IGetListParams & FilterParams): string => {
  const cursor = page ? getAfterCursor(page, pageSize) : ''
  let booleanFilterString = ''
  let filterString = ''

  if (booleanFilter) {
    booleanFilterString = `,booleanFilter:${parameterizeArrayofObjects(booleanFilter)}`
  }
  if (filter?.length) {
    filterString = `,filter:${parameterizeArrayofObjects(filter)}`
  }
  return gql`
  {
    partner(first: ${pageSize}, after: "${cursor}", sortField:ATTRIBUTE_INSTANCE_COUNT ${booleanFilterString} ${filterString}) {
      count
      edges {
        node {
          id
          name
          isGeneralPartner
          isContractDocumentAdded
          attributeInstance {
            count
          }
          userEntities {
            count
          }
          objects {
            count
          }
          attribute {
            count
          }
          impactedObjects: objects(booleanFilter: { key: HAS_ALERTS, value: true }) {
            count
          }
          alert(first: 999, filter: [{ key: STATUS, values: ["Active"] }]) {
            count
          }
        }
      }
    }
  }
`
}

// TODO: add type for graphql response
export const mapQueryPartnersCard = (raw: any): PartnerCardList => {
  try {
    if (raw.partner?.edges && raw.partner?.edges.length) {
      const cards = raw.partner?.edges.map(({ node }) => {
        return {
          id: node?.id,
          isContractDocumentAdded: node?.isContractDocumentAdded,
          name: node?.name || '',
          objectsCount: node?.objects?.count || 0,
          alertsCount: node?.alert?.count || 0,
          objectsImpactedCount: node?.impactedObjects?.count || 0,
          attributeCount: node?.attribute?.count || 0,
          attributeInstanceCount: node?.attributeInstance?.count || 0,
          entitiesCount: node?.userEntities?.count || 0,
          isGeneralPartner: node?.isGeneralPartner
        }
      })
      const agreegatedCards = [...cards.filter((item) => !item.isGeneralPartner)]
      return { cards: agreegatedCards, total: raw?.partner?.count || 0 }
    }
    return { cards: [], total: raw?.partner?.count || 0 }
  } catch (e) {
    console.error(e)
    return { cards: [], total: 0 }
  }
}

export const queryPartnersList = ({
  page,
  pageSize = LIMIT_DEFAULT,
  partnerId,
  filter,
  booleanFilter
}: IGetListParams & FilterParams): string => {
  const cursor = page ? getAfterCursor(page, pageSize) : ''

  let idFilter = ''
  let booleanFilterString = ''
  let filterString = ''
  if (partnerId) {
    idFilter = `,id:"${partnerId}"`
  }
  if (booleanFilter) {
    booleanFilterString = `,booleanFilter:${parameterizeArrayofObjects(booleanFilter)}`
  }
  if (filter?.length) {
    filterString = `,filter:${parameterizeArrayofObjects(filter)}`
  }

  return gql`
    {
      partner(first: ${pageSize}, after: "${cursor}", sortField:ATTRIBUTE_INSTANCE_COUNT  ${idFilter} ${booleanFilterString} ${filterString} ) {
        count
        edges {
          node {
            id
            name
            isGeneralPartner
            attributeInstance {
              count
            }
            userEntities {
              count
            }
            objects {
              count
            }
            piiobject: objects(booleanFilter: {key: IS_SENSITIVE, value: true}) {
              count
            }
            attribute {
              count
            }
            datasources {
              count
            }
          }
        }
      }
    }
  `
}

export const queryExternalMembersList = ({
  filter,
  booleanFilter
}: IGetListParams & FilterParams): string => {
  let booleanFilterString = ''
  let filterString = ''

  if (booleanFilter) {
    booleanFilterString = `,booleanFilter:${parameterizeArrayofObjects(booleanFilter)}`
  }
  if (filter?.length) {
    filterString = `,filter:${parameterizeArrayofObjects(filter)}`
  }

  return gql`
    {
      partner(${booleanFilterString} ${filterString}) {
        count
        edges {
          node {
            id
            name
            isGeneralPartner
            attributeInstance {
              count
            }
            userEntities {
              count
            }
            objects {
              count
            }
            piiobject: objects(booleanFilter: { key: IS_SENSITIVE, value: true }) {
              count
            }
            attribute {
              count
            }
            datasources {
              count
            }
          }
        }
      }
    }
  `
}

// TODO: add type for graphql response
export const mapQueryPartnersList = (raw: any): PartnerList => {
  try {
    if (raw.partner?.edges && raw.partner?.edges.length) {
      const list = raw.partner?.edges.map(({ node }) => {
        return {
          id: node.id,
          name: node.name,
          isGeneralPartner: node.isGeneralPartner,
          objectsCount: node.objects?.count || 0,
          attributeCount: node.attribute?.count || 0,
          attributeInstanceCount: node.attributeInstance?.count || 0,
          entitiesCount: node.userEntities?.count || 0,
          datasourceCount: node.datasources?.count || 0,
          piiObjectCount: node.piiobject?.count || 0
        }
      })
      return {
        list: list,
        total: raw?.partner?.count || 0
      }
    }
    return { list: [], total: 0 }
  } catch (e) {
    console.error(e)
    return { total: 0 }
  }
}

export const mapQueryExternalMembersList = (raw: any): ExternalMembersList => {
  try {
    if (raw.partner?.edges && raw.partner?.edges.length) {
      const list = raw.partner?.edges.map(({ node }) => {
        return {
          id: node.id,
          name: node.name,
          isGeneralPartner: node.isGeneralPartner,
          objectsCount: node.objects?.count || 0,
          attributeCount: node.attribute?.count || 0,
          attributeInstanceCount: node.attributeInstance?.count || 0,
          entitiesCount: node.userEntities?.count || 0,
          datasourceCount: node.datasources?.count || 0,
          piiObjectCount: node.piiobject?.count || 0,
          isContractDocumentAdded: node.isContractDocumentAdded,
          alertsCount: node.alert?.count || 0,
          objectsImpactedCount: node.impactedObjects?.count || 0
        }
      })
      const externalMembersList = [getAggregateGeneralPartners(list)]
      return { membersList: externalMembersList }
    }
    return { membersList: [] }
  } catch (e) {
    console.error(e)
    return { membersList: [] }
  }
}

export const queryGenericPartnerDomains = (): string => {
  return gql`
    {
      partner(booleanFilter: { key: IS_GENERAL_PARTNER, value: true }) {
        edges {
          node {
            name
            domain
            objects {
              count
            }
          }
        }
      }
    }
  `
}
export const mapQueryGenericPartnerDomains = (raw: any): { topList } => {
  const topList = raw.partner.edges.map(({ node: object }) => ({
    domainName: object.domain,
    objectCount: object.objects?.count || 0
  }))

  return { topList }
}

export const queryDatasourceTopWidget = (params: IGetAttributesFilterParams): string => {
  const { filter } = params
  let commonParamString = ''
  const filterString = filter ? parameterizeArrayofObjects(filter) : ''
  if (filterString) {
    commonParamString += `,filter: ${filterString}`
  }
  if (commonParamString) {
    commonParamString = '(' + commonParamString + ')'
  }
  return gql`
    {
      objectGroupByDatasourceType${commonParamString} {
        edges {
          node {
            datasourceType
            objects {
              count
            }
          }
        }
      }
    }
  `
}
export const mapQueryDatasourceTopWidget = (raw: any): { topList } => {
  const topList = raw.objectGroupByDatasourceType.edges.map(({ node: object }) => ({
    datasourceType: object.datasourceType,
    objectCount: object.objects?.count || 0
  }))

  return { topList }
}

export const queryPartnerObjectsSharedGroupByDataSourceType = (params: FilterParams): string => {
  const { filter } = params
  const filterString = filter ? parameterizeArrayofObjects(filter) : ''

  return gql`
    {
      objectGroupByDatasourceType(filter: ${filterString}){
        edges{
          node{
            datasourceType
            objects{
              count
            }
            attributeInstance{
              count
            }
          }
        }
        count
      }
    }
  `
}

export const mapQueryPartnerObjectsSharedGroupByDataSourceType = (
  raw: any
): {
  list: ObjectShared[]
  datasourcesCount: number
  objectsCount: number
  displayedDataSourceTypes: string[]
} => {
  try {
    const list =
      raw.objectGroupByDatasourceType?.edges?.map(
        ({ node: { datasourceType, objects, attributeInstance } }) => ({
          instancesCount: attributeInstance?.count || 0,
          objectsCount: objects?.count || 0,
          datasourceType: mapDataSourceApiTypesToStandard(datasourceType)
        })
      ) || []
    const displayedDataSourceTypes: string[] =
      (raw.objectGroupByDatasourceType?.edges || []).map(({ node: { datasourceType } }) =>
        mapDataSourceApiTypesToStandard(datasourceType)
      ) || []
    const totalOjectsCount =
      list.reduce((acc = 0, current) => (acc += current.objectsCount || 0), 0) || 0
    return {
      list,
      datasourcesCount: raw.objectGroupByDatasourceType?.count || 0,
      objectsCount: totalOjectsCount,
      displayedDataSourceTypes
    }
  } catch (e) {
    console.error(e)
    return { list: [], datasourcesCount: 0, objectsCount: 0, displayedDataSourceTypes: [] }
  }
}

export const queryPartnerObjectsSharedGroupBySender = (params: FilterParams): string => {
  const { filter } = params
  const filterString = filter ? parameterizeArrayofObjects(filter) : ''
  return gql`
    {
      objectGroupBySender(filter: ${filterString}){
        count
        edges{
          node{
            sender{
              email
              name
            }
            objects{
              count
            }
          }
        }
      }
    }
  `
}

export const mapQueryPartnerObjectsSharedGroupBySender = (
  raw: any
): { list: ObjectShared[]; sendersCount: number; objectsCount: number } => {
  try {
    const list =
      raw.objectGroupBySender?.edges?.map(({ node: { objects, sender } }) => ({
        senderEmail: sender?.email || '',
        objectsCount: objects?.count || 0,
        senderName: sender?.name || ''
      })) || []
    const totalOjectsCount =
      list.reduce((acc = 0, current) => (acc += current.objectsCount || 0), 0) || 0
    return {
      list,
      objectsCount: totalOjectsCount,
      sendersCount: raw.objectGroupBySender.count || 0
    }
  } catch (e) {
    console.error(e)
    return { list: [], sendersCount: 0, objectsCount: 0 }
  }
}

export const queryPartnerObjectsSharedList = (
  params: FetchPartnerObjectsSharedListParams
): string => {
  const { filters, page, pageSize = LIMIT_DEFAULT, datasourceType } = params
  const cursor = page ? getAfterCursor(page, pageSize) : ''
  let filterString = filters.filter ? `filter:${parameterizeArrayofObjects(filters.filter)}` : ''
  if (filters.booleanFilter && filters.booleanFilter.length > 0) {
    filterString += `, booleanFilter:${parameterizeArrayofObjects(filters.booleanFilter)}`
  }

  const datasourceTypeFilter = datasourceType ? `, datasourceTypes: ${datasourceType}` : ''
  return gql`
    {
      objectsCount: objects(${filterString} ${datasourceTypeFilter}){
      count
    }
      objects(first: ${pageSize}, after: "${cursor}", ${filterString} ${datasourceTypeFilter}) {
        edges {
          node {
            id
            name
            type
            lastModifiedTime
            datasource {
              edges {
                node {
                  id
                  name
                  type
                }
              }
            }
            sharedBy {
              name
              email
            }
            objectList {
              __typename
              ... on Mail {
                messageId
                recipients {
                  name
                  email
                }
                sender {
                  email
                  name
                }
                subject
              }
            }
            entity {
              edges {
                node {
                  id
                  name
                }
              }
            }
            attribute {
              count
              edges {
                node {
                  name
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryPartnerObjectsSharedList = (
  raw: any
): { list: ObjectShared[]; objectsCount: number } => {
  try {
    const list = raw.objects.edges.map(
      ({
        node: {
          name = '',
          datasource,
          attribute,
          entity,
          type,
          id,
          lastModifiedTime = '',
          objectList,
          sharedBy
        }
      }) => ({
        lastModifiedTime,
        objectId: id,
        objectType: type,
        objectName: name,
        datasourceName: datasource.edges[0].node.name,
        datasourceType: datasource.edges[0].node.type,
        datasourceId: datasource.edges[0].node.id,
        attributesCount: attribute.count || 0,
        attributes: attribute.edges.map(({ node: { name = '' } }) => name),
        entitiesCount: entity.count || 0,
        entities: entity.edges.map(({ node }) => ({ ...node })) || [],
        senderEmail: objectList?.sender?.email || '',
        messageId: objectList?.messageId || '',
        recipients: objectList?.recipients?.map(({ email }) => email),
        subject: objectList?.subject || '',
        sharedBy: sharedBy.name || sharedBy.email || ''
      })
    )
    return {
      list,
      objectsCount: raw.objectsCount.count || 0
    }
  } catch (e) {
    console.error(e)
    return { list: [], objectsCount: 0 }
  }
}

export const queryRegisterPartner = (params: PartnerInfo): string => {
  const {
    name,
    domain,
    partnerManager,
    contractUrl,
    partnerContact,
    dataExchangePurpose,
    permittedAttributes
  } = params

  const queryTransformed = gqlast`
    mutation{
      createPartner(clientMutationId:"1"
        partnerData: {
          name:${name}
          domain: ${domain}
          contractUrl: ${contractUrl}
          partnerManager: ${partnerManager}
          partnerContact: ${partnerContact}
          dataExchangePurpose: ${dataExchangePurpose}
          permittedAttributes:  ${permittedAttributes}
        }){
        partner{
          edges{
            node{
              id
            }
          }
        }
        clientMutationId
      }
    }
  `

  return gql`
    ${queryTransformed}
  `
}

export const queryUpdatePartner = (params: PartnerInfo): string => {
  const {
    id,
    name,
    partnerManager,
    contractUrl,
    partnerContact,
    dataExchangePurpose,
    permittedAttributes
  } = params

  const queryTransformed = gqlast`
  mutation{
    updatePartnerDetails(clientMutationId:"1",
      partnerData: {
        Id: ${id},
        name:${name}
        contractUrl: ${contractUrl}
        partnerManager: ${partnerManager}
        partnerContact: ${partnerContact}
        dataExchangePurpose: ${dataExchangePurpose}
        permittedAttributes:  ${permittedAttributes}
      }){
        partner{
          edges{
            node{
              id
            }
          }
        }
        clientMutationId
      }
    }
  `

  return gql`
    ${queryTransformed}
  `
}

export const queryPartnerById = (id: string): string => {
  return gql`
  {
    partner(id:"${id}"){
      edges{
        node{
          id
          name
          domain
          contractUrl
          dataExchangePurpose
          partnerManager
          partnerContact
          permittedAttributes{
            attributeIds
            attributeSetId
          }
        }
      }
    }
  }
  `
}

export const mapQueryPartnerById = (raw: any): { partner?: PartnerInfo } => {
  try {
    return {
      partner: {
        ...raw.partner.edges[0].node
      } as PartnerInfo
    }
  } catch (e) {
    console.error(e)
    return { partner: undefined }
  }
}

export const queryPartnerSummary = (activePartnerId: string): string => {
  const isGeneralPartner = activePartnerId === GENERIC_EMAIL_PARTNERS
  const params = isGeneralPartner
    ? `booleanFilter: { key: IS_GENERAL_PARTNER, value: true }`
    : `id: "${activePartnerId}"`

  return gql`
  {
    partner(${params}) {
      count
      edges {
        node {
          name
          isGeneralPartner
          objects {
            count
          }
          datasources{
            edges{
              node{
                id
                name
                type
              }
            }
          }
        }
      }
    }
  }
`
}

export const mapQueryPartnerSummary = (raw: any): PartnerSumary => {
  try {
    const { node } = raw?.partner?.edges[0] ?? {}
    const isGeneralPartner = node?.isGeneralPartner
    return {
      objectCount: node?.objects?.count ?? 0,
      partnerName: isGeneralPartner ? EXTERNAL_EMAIL_GROUP_TEXT : node?.name ?? '',
      associatedDatasources: node?.datasources?.edges?.map(({ node }) => node) || []
    }
  } catch (e) {
    console.error(e)
    return { objectCount: 0, partnerName: '', associatedDatasources: [] }
  }
}
