import './chartBarSimple.less'
import ChartTooltip from '../ChartTooltip'
import useDebounceResize from '../../hooks/useDebounceResize'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import React, { ReactElement, SVGProps } from 'react'
import { AxisInterval } from 'recharts/types/util/types'

export type ChartBarDataItem = {
  [key: string]: string | number | object
}

interface IProps {
  data: ChartBarDataItem[]
  barColors?: { key: string; color: string }[]
  barColor?: string
  className?: string
  xLabel?: string
  yLabel?: string
  barSize?: number
  barGap?: number
  barCategoryGap?: number
  xLabelAngle?: number
  showAxisLine?: boolean
  defaultTooltip?: boolean
  cartesianGridHorrizontal?: boolean
  cartesianGridVertical?: boolean
  yLabelFormatter?: (value: string) => string
  onClick?: () => void
  toolTipFormatter?: (val: number) => string
  xAxisAngle?: number
  xAxisTickInterval?: AxisInterval
  xAxisLine?: boolean
  yAxisLine?: boolean
  chartResponsiveHeight?: number
  tickMargin?: number
  print?: boolean
  tickFontSize?: { fontSize: string }
  tickFormatter?: SVGProps<SVGTextElement>
  chartMargin?: { top: number; right: number; left: number; bottom: number }
  XTickFormatter?: (value: any) => string
  YTickFormatter?: (value: any) => string
  renderLegend?: () => ReactElement
  isAnimationActive?: boolean
  barPadding?: {
    dx?: number
    dy?: number
  }
  xAxisLabelPadding?: {
    dx?: number
    dy?: number
  }
  yAxisLabelPadding?: {
    dx?: number
    dy?: number
  }
  customToolTip?: React.ReactElement
  onBarItemClick?: (val) => void
  multiColored?: boolean
  onBarHover?: (val) => void
  minPointSize?: number
  xAxisHeight?: number
}

const CursorWithClick = ({ onClick, ...rest }) => {
  const payload = rest?.payload?.[0]
  const handleCursorClick = () => {
    payload && onClick(payload.payload)
  }

  delete rest.pointEvents
  delete rest.payload
  delete rest.payloadIndex

  return (
    <svg cursor={onClick ? 'pointer' : 'auto'} onClick={handleCursorClick}>
      <rect {...rest} pointerEvents="all"></rect>
    </svg>
  )
}

const DEBOUNCE_DELAY = 500

const ChartBarSimple = (props: IProps): React.ReactElement => {
  const {
    data,
    defaultTooltip = false,
    barSize = 20,
    barGap,
    barCategoryGap,
    barColor = '#82ca9d',
    className = '',
    barColors = [],
    yLabelFormatter,
    cartesianGridHorrizontal = false,
    cartesianGridVertical = false,
    xLabel = '',
    yLabel = '',
    onClick,
    toolTipFormatter,
    renderLegend,
    isAnimationActive,
    print = false,
    xAxisAngle = 24,
    xAxisTickInterval = 'preserveStartEnd',
    xAxisLine = false,
    yAxisLine = false,
    chartResponsiveHeight = 150,
    tickMargin = 0,
    tickFontSize = { fontSize: '0.72rem' },
    tickFormatter,
    chartMargin = { top: 5, right: 30, left: 20, bottom: 5 },
    XTickFormatter,
    YTickFormatter,
    barPadding,
    xAxisLabelPadding,
    yAxisLabelPadding,
    customToolTip,
    onBarItemClick,
    multiColored = false,
    onBarHover,
    minPointSize = 0,
    xAxisHeight
  } = props
  const tickFormat = tickFormatter ? tickFormatter : tickFontSize

  const resizeKey = useDebounceResize(DEBOUNCE_DELAY)

  return (
    <div
      className={`chart-bar-simple custom-scrollbar ${className}`}
      onClick={onClick}
      data-test-id="chart-bar-simple"
      {...(print ? { style: { width: '1100px' } } : {})}
    >
      <ResponsiveContainer
        key={resizeKey}
        height={chartResponsiveHeight}
        className={`custom-scrollbar ${className} responsive-container`}
        {...(print ? { width: '99%' } : {})}
      >
        <BarChart
          data={data}
          margin={chartMargin}
          {...(barCategoryGap !== undefined ? { barCategoryGap } : { barSize })}
          {...(isAnimationActive ? { isAnimationActive } : {})}
          {...(barGap !== undefined ? { barGap } : {})}
          {...(xAxisHeight ? { height: chartResponsiveHeight - xAxisHeight } : {})}
        >
          <CartesianGrid vertical={cartesianGridVertical} horizontal={cartesianGridHorrizontal} />
          <XAxis
            axisLine={xAxisLine} // do not show bottom axis line
            dataKey="name" // data key to render names
            interval={xAxisTickInterval} // show all the labels
            tick={tickFormat} // label styles
            angle={xAxisAngle}
            tickMargin={tickMargin}
            tickFormatter={XTickFormatter}
            width={barSize || 20}
            {...(xAxisHeight ? { height: xAxisHeight } : {})}
          >
            {xLabel && (
              <Label
                dx={xAxisLabelPadding?.dx}
                dy={xAxisLabelPadding?.dy}
                value={xLabel}
                className="axis-label x"
              />
            )}
          </XAxis>
          <YAxis
            axisLine={yAxisLine}
            {...(yLabelFormatter ? { tickFormatter: yLabelFormatter } : {})}
            tick={tickFormat}
            interval="preserveStartEnd"
            allowDecimals={false}
            tickFormatter={YTickFormatter}
          >
            {yLabel && (
              <Label
                angle={-90}
                dx={yAxisLabelPadding?.dx}
                dy={yAxisLabelPadding?.dy}
                value={yLabel}
                position="center"
                className="axis-label y"
              />
            )}
          </YAxis>
          <Tooltip
            {...(defaultTooltip
              ? {}
              : {
                  content: customToolTip ? (
                    customToolTip
                  ) : (
                    <ChartTooltip formatter={toolTipFormatter} />
                  )
                })}
            cursor={
              onBarItemClick ? (
                <CursorWithClick onClick={onBarItemClick} />
              ) : (
                { cursor: onBarItemClick ? 'pointer' : 'auto', pointerEvents: 'all' }
              )
            }
            wrapperStyle={{ zIndex: 1000 }}
          />
          {barColors.length > 0 && !multiColored ? (
            barColors.map(({ key, color }) => (
              <Bar
                onClick={onBarItemClick}
                key={key}
                dataKey={key}
                fill={color}
                dx={barPadding?.dx}
                dy={barPadding?.dy}
                barSize={barSize}
                onMouseEnter={() => onBarHover?.(key)}
                onMouseLeave={() => onBarHover?.(null)}
                cursor={onBarItemClick ? 'pointer' : 'auto'}
                {...(minPointSize !== 0 ? { minPointSize } : {})}
              />
            ))
          ) : multiColored ? (
            <Bar
              onClick={onBarItemClick}
              dataKey="value"
              barSize={barSize}
              cursor={onBarItemClick ? 'pointer' : 'auto'}
              {...(minPointSize !== 0 ? { minPointSize } : {})}
            >
              {data.map(({ name, color }) => (
                <Cell
                  onClick={onBarItemClick}
                  key={name as string}
                  fill={color as string}
                  dx={barPadding?.dx}
                  dy={barPadding?.dy}
                />
              ))}
            </Bar>
          ) : (
            <Bar
              onClick={onBarItemClick}
              dataKey="value"
              fill={barColor}
              cursor={onBarItemClick ? 'pointer' : 'auto'}
              barSize={barSize}
              {...(minPointSize !== 0 ? { minPointSize } : {})}
            />
          )}
          {renderLegend && <Legend content={renderLegend} />}
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default ChartBarSimple
